import React, { useEffect } from 'react';
import $ from 'jquery';

const Home = () => {
    useEffect(() => {
        // jika lebar window kurang dari 992px maka trigger click button close-sidebar
        if ($(window).width() < 992 && $('.mobile-toggle-nav.is-active').length > 0) {
            $('.app-header__mobile-menu .mobile-toggle-nav-left').click();
        }
    }, []);

    return (<>
        <div className="app-page-title">
            <div className="page-title-wrapper">
                <div className="page-title-heading">
                    <div className="page-title-icon">
                        <i className="pe-7s-rocket icon-gradient bg-love-kiss"></i>
                    </div>
                    <div>
                        Dashboard
                        <div className="page-title-subheading">Welcome to YHC Users Management</div>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12">
                <div className="main-card mb-3 card">
                    <div className="card-body">
                        <img className="img-fluid" src="/assets/images/YHC-2019.png" alt="Welcome" />
                    </div>
                </div>
            </div>
        </div>
    </>);
}
 
export default Home;