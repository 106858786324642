import React, { useEffect } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import $ from 'jquery';

const Sidebar = (props) => {
    useEffect(() => {
        $('.vertical-nav-menu li').removeClass('mm-active');
        $('.vertical-nav-menu li a').removeClass('mm-active');
        $('.vertical-nav-menu li ul').removeClass('mm-show');
        setTimeout(() => {
            if (props.history.location.pathname.search("/user/dashboard") > -1) {
                $("#li-dashboard").addClass('mm-active');
            } else if (props.history.location.pathname.search("/user/profile") > -1) {
                $("#li-profile").addClass('mm-active');
                $("#li-profile ul").addClass('mm-show');
                if (props.history.location.pathname.search("/user/profile/detail") > -1) {
                    $("#a-profile-detail").addClass('mm-active');
                } else if (props.history.location.pathname.search("/user/profile/form") > -1) {
                    $("#a-profile-form").addClass('mm-active');
                }
            }
        }, 200);
    });

    return (
    <div className="app-sidebar sidebar-shadow">
        <div className="app-header__logo">
            <div className="logo-src"></div>
            <div className="header__pane ml-auto">
                <div>
                    <button type="button" className="hamburger close-sidebar-btn hamburger--elastic" data-class="closed-sidebar">
                        <span className="hamburger-box">
                            <span className="hamburger-inner"></span>
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <div className="app-header__mobile-menu">
            <div>
                <button type="button" className="hamburger hamburger--elastic mobile-toggle-nav">
                    <span className="hamburger-box">
                        <span className="hamburger-inner"></span>
                    </span>
                </button>
            </div>
        </div>
        <div className="app-header__menu">
            <span>
                <button type="button" className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                    <span className="btn-icon-wrapper">
                        <i className="fa fa-ellipsis-v fa-w-6"></i>
                    </span>
                </button>
            </span>
        </div>
        <div className="scrollbar-sidebar">
            <div className="app-sidebar__inner">
                <ul className="vertical-nav-menu">
                    <li className="app-sidebar__heading">Menu</li>
                    <li>
                        <NavLink to="/user/dashboard" id="li-dashboard">
                            <i className="metismenu-icon pe-7s-rocket"></i>
                            Dashboard
                        </NavLink>
                    </li>
                    <li id="li-profile">
                        <NavLink to="#" onClick={e => e.preventDefault()}>
                            <i className="metismenu-icon pe-7s-user"></i>
                            Profile
                            <i className="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                        </NavLink>
                        <ul>
                            <li>
                                <NavLink to="/user/profile/detail" id="a-profile-detail">
                                    <i className="metismenu-icon"></i>
                                    Detail
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/user/profile/form" id="a-profile-form">
                                    <i className="metismenu-icon"></i>
                                    Form
                                </NavLink>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    );
}

export default withRouter(Sidebar);