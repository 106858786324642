import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import jwt_decode from "jwt-decode";

const Header = (props) => {
    const user = jwt_decode(JSON.parse(localStorage.getItem('admin-token')).token).data;
    const logoutHandler = e => {
        localStorage.clear();
        props.history.push(`/login`);
    }

    if (user === undefined) {
        localStorage.clear();
        props.history.push(`/login`);
    }

    return (
    <div className="app-header header-shadow">
        <div className="app-header__logo">
            {/* <div className="logo-src"></div> */}
            <img className="img-fluid" src="/assets/images/YHC-2019.png" alt="YHC" width="200" />
            <div className="header__pane ml-auto">
                <div>
                    <button type="button" className="hamburger close-sidebar-btn hamburger--elastic" data-class="closed-sidebar">
                        <span className="hamburger-box">
                            <span className="hamburger-inner"></span>
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <div className="app-header__mobile-menu">
            <div>
                <button type="button" className="hamburger hamburger--elastic mobile-toggle-nav mobile-toggle-nav-left">
                    <span className="hamburger-box">
                        <span className="hamburger-inner"></span>
                    </span>
                </button>
            </div>
        </div>
        <div className="app-header__menu">
            <span>
                <button type="button" className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                    <span className="btn-icon-wrapper">
                        <i className="fa fa-ellipsis-v fa-w-6"></i>
                    </span>
                </button>
            </span>
        </div>
        <div className="app-header__content">
            <div className="app-header-left">
                <div className="search-wrapper">
                    <div className="input-holder">
                        <input type="text" className="search-input" placeholder="Type to search" />
                        <button className="search-icon">
                            <span></span>
                        </button>
                    </div>
                    <button className="close"></button>
                </div>
            </div>
            <div className="app-header-right">
                <div className="header-btn-lg pr-0">
                    <div className="widget-content p-0">
                        <div className="widget-content-wrapper">
                            <div className="widget-content-left">
                                <div className="btn-group">
                                    <NavLink to="#" onClick={e => e.preventDefault()} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="p-0 btn">
                                        <img width="42" className="rounded-circle" src="/assets/images/avatars/dummy-avatar.jpg" alt="avatar" />
                                        <i className="fa fa-angle-down ml-2 opacity-8"></i>
                                    </NavLink>
                                    <div tabIndex="-1" role="menu" aria-hidden="true"
                                        className={`rm-pointers dropdown-menu-lg dropdown-menu dropdown-menu-right`}>
                                        <div className="dropdown-menu-header">
                                            <div className="dropdown-menu-header-inner bg-info">
                                                <div className="menu-header-image opacity-2" style={{ backgroundImage: `url('/assets/images/dropdown-header/city3.jpg')` }}></div>
                                                <div className="menu-header-content text-left">
                                                    <div className="widget-content p-0">
                                                        <div className="widget-content-wrapper">
                                                            <div className="widget-content-left mr-3">
                                                                <img width="42" className="rounded-circle"
                                                                    src="/assets/images/avatars/dummy-avatar.jpg" alt="avatar" />
                                                            </div>
                                                            <div className="widget-content-left">
                                                                <div className="widget-heading">{user?.name}</div>
                                                                <div className="widget-subheading opacity-8">A short profile description</div>
                                                            </div>
                                                            <div className="widget-content-right mr-2">
                                                                <button type="button" onClick={logoutHandler} className="btn-pill btn-shadow btn-shine btn btn-focus">Logout</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="scroll-area-xs" style={{ height: '150px' }}>
                                            <div className="scrollbar-container ps">
                                                <ul className="nav flex-column">
                                                    <li className="nav-item-header nav-item">Activity</li>
                                                    <li className="nav-item">
                                                        <NavLink to="#" onClick={e => e.preventDefault()} className="nav-link">
                                                            Chat
                                                            <div className="ml-auto badge badge-pill badge-info">8</div>
                                                        </NavLink>
                                                    </li>
                                                    <li className="nav-item">
                                                        <NavLink to="#" onClick={e => e.preventDefault()} className="nav-link">Recover Password</NavLink>
                                                    </li>
                                                    <li className="nav-item-header nav-item">
                                                        My Account
                                                    </li>
                                                    <li className="nav-item">
                                                        <NavLink to="#" onClick={e => e.preventDefault()} className="nav-link">
                                                            Settings
                                                            <div className="ml-auto badge badge-success">New</div>
                                                        </NavLink>
                                                    </li>
                                                    <li className="nav-item">
                                                        <NavLink to="#" onClick={e => e.preventDefault()} className="nav-link">
                                                            Messages
                                                            <div className="ml-auto badge badge-warning">512</div>
                                                        </NavLink>
                                                    </li>
                                                    <li className="nav-item">
                                                        <NavLink to="#" onClick={e => e.preventDefault()} className="nav-link">Logs</NavLink>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <ul className="nav flex-column">
                                            <li className="nav-item-divider mb-0 nav-item"></li>
                                        </ul>
                                        <div className="grid-menu grid-menu-2col">
                                            <div className="no-gutters row">
                                                <div className="col-sm-6">
                                                    <button className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-warning">
                                                        <i className="pe-7s-chat icon-gradient bg-amy-crisp btn-icon-wrapper mb-2"></i>
                                                        Message Inbox
                                                    </button>
                                                </div>
                                                <div className="col-sm-6">
                                                    <button className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-danger">
                                                        <i className="pe-7s-ticket icon-gradient bg-love-kiss btn-icon-wrapper mb-2"></i>
                                                        <b>Support Tickets</b>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <ul className="nav flex-column">
                                            <li className="nav-item-divider nav-item"></li>
                                            <li className="nav-item-btn text-center nav-item">
                                                <button className="btn-wide btn btn-primary btn-sm"> Open Messages</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="widget-content-left  ml-3 header-user-info">
                                <div className="widget-heading"> {user?.name}</div>
                                <div className="widget-subheading"> {user?.email}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}
 
export default withRouter(Header);