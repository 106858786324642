import axios from 'axios';

export default {
    refresh: (baseUrl, user, role) => axios({
        method: 'post',
        url: `${baseUrl}/refresh-tokens`,
        responseType: 'json',
        data: {
            'refresh_token': user.refresh_token,
            'email': user.email,
        },
        headers: {
            'Accept': 'application/json'
        }
    }).then(res => {
        localStorage.setItem(
            "token",
            JSON.stringify({
                ...user,
                token: res.data.data.token,
            })
        );
        localStorage.setItem(
            `${role}-token`,
            JSON.stringify({
                ...user,
                token: res.data.data.token,
            })
        );
        // console.log(res);
        window.location.reload();
    }).catch(err => {
        localStorage.clear();
    }),
}