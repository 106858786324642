import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import $ from 'jquery';
import { toast } from "react-toastify";
import { UserContext } from '../../../components/UserContext';

const LainLain = (props) => {
    const { user } = useContext(UserContext);
    const [isSubmitting, setSubmitting] = useState(false);

    const [kegiatanOrganisasi, setKegiatanOrganisasi] = useState([
        {
            no: '1.',
            nama: '',
            jenis: '',
            jabatan: '',
            tahun: '',
        },
        {
            no: '2.',
            nama: '',
            jenis: '',
            jabatan: '',
            tahun: '',
        },
        {
            no: '3.',
            nama: '',
            jenis: '',
            jabatan: '',
            tahun: '',
        },
        {
            no: '4.',
            nama: '',
            jenis: '',
            jabatan: '',
            tahun: '',
        },
        {
            no: '5.',
            nama: '',
            jenis: '',
            jabatan: '',
            tahun: '',
        },
    ]);
    const [infoFisik, setInfoFisik] = useState({
        tinggi_badan: '',
        berat_badan: '',
        gol_darah: '',
        ukuran_pakaian: '',
        ukuran_sepatu: '',
    });
    const [pihakDarurat, setPihakDarurat] = useState({
        nama: '',
        hubungan: '',
        alamat: '',
        no_telp_rumah: '',
        no_telp_kantor: '',
    });

    const handleInputChange = (index, event) => {
        const values = [...kegiatanOrganisasi];
        switch (event.target.name) {
            case 'nama':
                values[index].nama = event.target.value;
                break;
            case 'jenis':
                values[index].jenis = event.target.value;
                break;
            case 'jabatan':
                values[index].jabatan = event.target.value;
                break;
            default:
                values[index].tahun = event.target.value;
                break;
        }

        setKegiatanOrganisasi(values);
    }

    const handleChangeInfoFisik = e => {
        setInfoFisik({
            ...infoFisik,
            [e.target.name]: e.target.value
        });
    }

    const handleChangePihakDarurat = e => {
        setPihakDarurat({
            ...pihakDarurat,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = async e => {
        e.preventDefault();

        let body = $("html, body");
        body.stop().animate({scrollTop:0}, 1000, 'swing');

        try {
            setSubmitting(true);

            await axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_HOST}/users/user/post-data-user/lain-lain`,
                responseType: 'json',
                data: {
                    email: props.email,
                    kegiatan_organisasi: kegiatanOrganisasi,
                    info_fisik: infoFisik,
                    pihak_darurat: pihakDarurat,
                },
                headers: {
                    'Authorization': user.token,
                    'Accept': 'application/json'
                }
            }).then(response => {
                setSubmitting(false);
                toast.success(response.data, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // redirect back
                props.history.push(`/user/profile/detail`);
            }).catch(err => {
                setSubmitting(false);
                if (err.response.status === 400) {
                    Swal.fire("Oops..", err.response.data, "error");
                } else {
                    localStorage.clear();
                    props.history.push(`/login`);
                }
            });
        } catch (e) {
            setSubmitting(false);
        }
    }

    const editData = () => {
        let kegiatan_organisasi = props.data.kegiatan_organisasi.split("|");
        let info_fisik = props.data.info_fisik.split("|");
        let pihak_darurat = props.data.pihak_darurat.split("|");

        const kegiatanOrganisasiValues = [];
        let kegOrg;
        for (let i = 0; i < kegiatan_organisasi.length; i++) {
            kegOrg = kegiatan_organisasi[i].split("~");
            kegiatanOrganisasiValues.push({
                no: (i+1) + '.',
                nama: kegOrg[0],
                jenis: kegOrg[1],
                jabatan: kegOrg[2],
                tahun: kegOrg[3],
            });
        }
        setKegiatanOrganisasi(kegiatanOrganisasiValues);

        const newInfoFisik = {...infoFisik};
        newInfoFisik.tinggi_badan = info_fisik[0];
        newInfoFisik.berat_badan = info_fisik[1];
        newInfoFisik.gol_darah = info_fisik[2];
        newInfoFisik.ukuran_pakaian = info_fisik[3];
        newInfoFisik.ukuran_sepatu = info_fisik[4];
        setInfoFisik(newInfoFisik);
        $("#ukuran_pakaian").val(info_fisik[3]);

        const newPihakDarurat = {...pihakDarurat};
        newPihakDarurat.nama = pihak_darurat[0];
        newPihakDarurat.hubungan = pihak_darurat[1];
        newPihakDarurat.alamat = pihak_darurat[2];
        newPihakDarurat.no_telp_rumah = pihak_darurat[3];
        newPihakDarurat.no_telp_kantor = pihak_darurat[4];
        setPihakDarurat(newPihakDarurat);
    }

    useEffect(() => {
        // jika form edit
        if (props.data.uid && props.data.kegiatan_organisasi && props.data.info_fisik && props.data.pihak_darurat) {
            editData();
        }
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [props.data]);

    return (<>
        <form onSubmit={handleSubmit} noValidate>
            <p className="mb-2 font-weight-bold">1. Kegiatan Organisasi</p>
            <div className="table-responsive">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th style={{ minWidth: '150px' }}>Nama Organisasi</th>
                            <th style={{ minWidth: '150px' }}>Jenis Organisasi</th>
                            <th style={{ minWidth: '150px' }}>Jabatan</th>
                            <th style={{ minWidth: '150px' }}>Tahun/Periode</th>
                        </tr>
                    </thead>
                    <tbody>
                        {kegiatanOrganisasi.map((d, i) => {
                            return <tr key={i}>
                                <td>{d.no}</td>
                                <td>
                                    <div className="position-relative form-group">
                                        <input name="nama" type="text" className="form-control" required="" defaultValue={d.nama} onChange={e => handleInputChange(i, e)} disabled={isSubmitting} maxLength="255" />
                                    </div>
                                </td>
                                <td>
                                    <div className="position-relative form-group">
                                        <input name="jenis" type="text" className="form-control" required="" defaultValue={d.jenis} onChange={e => handleInputChange(i, e)} disabled={isSubmitting} maxLength="255" />
                                    </div>
                                </td>
                                <td>
                                    <div className="position-relative form-group">
                                        <input name="jabatan" type="text" className="form-control" required="" defaultValue={d.jabatan} onChange={e => handleInputChange(i, e)} disabled={isSubmitting} maxLength="255" />
                                    </div>
                                </td>
                                <td>
                                    <div className="position-relative form-group">
                                        <input name="tahun" type="text" className="form-control" required="" defaultValue={d.tahun} onChange={e => handleInputChange(i, e)} disabled={isSubmitting} maxLength="255" />
                                    </div>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            <br />
            <p className="mb-2 font-weight-bold">2. Informasi Fisik</p>
            <div className="table-responsive">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Tinggi Badan</th>
                            <th>Berat Badan</th>
                            <th>Gol. Darah</th>
                            <th>Ukuran Pakaian</th>
                            <th>Ukuran Sepatu</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div className="position-relative form-group">
                                    <input name="tinggi_badan" type="text" className="form-control" required="" defaultValue={infoFisik.tinggi_badan} onChange={handleChangeInfoFisik} disabled={isSubmitting} maxLength="255" />
                                </div>
                            </td>
                            <td>
                                <div className="position-relative form-group">
                                    <input name="berat_badan" type="text" className="form-control" required="" defaultValue={infoFisik.berat_badan} onChange={handleChangeInfoFisik} disabled={isSubmitting} maxLength="255" />
                                </div>
                            </td>
                            <td>
                                <div className="position-relative form-group">
                                    <input name="gol_darah" type="text" className="form-control" required="" defaultValue={infoFisik.gol_darah} onChange={handleChangeInfoFisik} disabled={isSubmitting} maxLength="255" />
                                </div>
                            </td>
                            <td>
                                <div className="position-relative form-group">
                                    <select name="ukuran_pakaian" className="form-control" required="" defaultValue={infoFisik.ukuran_pakaian} onChange={handleChangeInfoFisik} onBlur={handleChangeInfoFisik} disabled={isSubmitting} id="ukuran_pakaian">
                                        <option value="">-Ukuran Pakaian-</option>
                                        <option value="XS">XS</option>
                                        <option value="S">S</option>
                                        <option value="M">M</option>
                                        <option value="L">L</option>
                                        <option value="XL">XL</option>
                                        <option value="XXL">XXL</option>
                                    </select>
                                </div>
                            </td>
                            <td>
                                <div className="position-relative form-group">
                                    <input name="ukuran_sepatu" type="text" className="form-control" required="" defaultValue={infoFisik.ukuran_sepatu} onChange={handleChangeInfoFisik} disabled={isSubmitting} maxLength="255" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br />
            <p className="mb-2 font-weight-bold">3. Pihak yang dalam keadaan darurat yang bisa dihubungi:</p>
            <div className="position-relative form-group">
                <label htmlFor="pd-nama" className="">a. Nama</label>
                <input name="nama" id="pd-nama" type="text" className="form-control" required="" defaultValue={pihakDarurat.nama} onChange={handleChangePihakDarurat} disabled={isSubmitting} maxLength="255" />
            </div>
            <div className="position-relative form-group">
                <label htmlFor="pd-hubungan" className="">b. Hubungan</label>
                <input name="hubungan" id="pd-hubungan" type="text" className="form-control" required="" defaultValue={pihakDarurat.hubungan} onChange={handleChangePihakDarurat} disabled={isSubmitting} maxLength="255" />
            </div>
            <div className="position-relative form-group">
                <label htmlFor="pd-alamat" className="">c. Alamat</label>
                <input name="alamat" id="pd-alamat" type="text" className="form-control" required="" defaultValue={pihakDarurat.alamat} onChange={handleChangePihakDarurat} disabled={isSubmitting} maxLength="255" />
            </div>
            <div className="position-relative form-group">
                <label htmlFor="pd-no_telp_rumah" className="">d. No Telp Rumah/HP</label>
                <input name="no_telp_rumah" id="pd-no_telp_rumah" type="text" className="form-control" required="" defaultValue={pihakDarurat.no_telp_rumah} onChange={handleChangePihakDarurat} disabled={isSubmitting} maxLength="255" />
            </div>
            <div className="position-relative form-group">
                <label htmlFor="pd-no_telp_kantor" className="">e. No Telp Kantor</label>
                <input name="no_telp_kantor" id="pd-no_telp_kantor" type="text" className="form-control" required="" defaultValue={pihakDarurat.no_telp_kantor} onChange={handleChangePihakDarurat} disabled={isSubmitting} maxLength="255" />
            </div>
            <div className="mt-4">
                <div className="divider"></div>
                <button className="btn-hover-shine btn btn-shadow btn-primary btn-wide btn-pill float-right" type="submit" id="btn-submit5" disabled={isSubmitting}>
                    {
                        !isSubmitting ?
                        <span>Save</span>
                        :
                        <span><img src="/assets/images/ajaxloader.gif" className="img-fluid" style={{width: '18px'}} alt="" /> Loading</span>
                    }
                </button>
                <button type="button" className="btn-shadow float-right btn-wide btn-pill mr-3 btn btn-outline-secondary" onClick={() => props.prev()}>Previous</button>
            </div>
        </form>
    </>);
}
 
export default withRouter(LainLain);