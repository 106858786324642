import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import $ from 'jquery';
import { AdminContext } from '../../../components/AdminContext';

const StatusKeluarga = (props) => {
    const { admin } = useContext(AdminContext);
    const [form, setForm] = useState({
        id: '',
        pasangan: '',
        nama: '',
        gender: '',
        tempat_lahir: '',
        tanggal_lahir: '',
        pendidikan: '',
        pekerjaan: '',
    });
    const [error, setError] = useState({
        pasangan: '',
        nama: '',
        gender: '',
        tempat_lahir: '',
        tanggal_lahir: '',
        pendidikan: '',
        pekerjaan: '',
    });
    const [isSubmitting, setSubmitting] = useState(false);
    const [childInputFields, setChildInputFields] = useState([]);

    const handleChange = e => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
        setError({
            ...error,
            [e.target.name]: ''
        });
    }

    const handleInputChange = (index, event) => {
        const values = [...childInputFields];
        switch (event.target.name) {
            case 'nama_anak':
                values[index].nama_anak = event.target.value;
                break;
            case 'gender_anak':
                values[index].gender_anak = event.target.value;
                break;
            case 'tempat_lahir_anak':
                values[index].tempat_lahir_anak = event.target.value;
                break;
            case 'tanggal_lahir_anak':
                values[index].tanggal_lahir_anak = event.target.value;
                break;
            case 'pendidikan_anak':
                values[index].pendidikan_anak = event.target.value;
                break;
            default:
                values[index].pekerjaan_anak = event.target.value;
                break;
        }

        setChildInputFields(values);
    }

    const handleAddFields = () => {
        const values = [...childInputFields];
        values.push({
            nama_anak: '',
            gender_anak: '',
            tempat_lahir_anak: '',
            tanggal_lahir_anak: '',
            pendidikan_anak: '',
            pekerjaan_anak: ''
        });
        setChildInputFields(values);
    }

    const handleRemoveFields = index => {
        const values = [...childInputFields];
        values.splice(index, 1);
        setChildInputFields(values);
    }

    const handleSubmit = async e => {
        e.preventDefault();

        let body = $("html, body");
        body.stop().animate({scrollTop:0}, 1000, 'swing');

        try {
            setSubmitting(true);

            await axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_HOST}/users/post-data-users/status-keluarga`,
                responseType: 'json',
                data: {
                    id: (localStorage.getItem('uid')) ? localStorage.getItem('uid') : form.id,
                    pasangan: form.pasangan,
                    nama: form.nama,
                    gender: form.gender,
                    tempat_lahir: form.tempat_lahir,
                    tanggal_lahir: form.tanggal_lahir,
                    pendidikan: form.pendidikan,
                    pekerjaan: form.pekerjaan,
                    childs: childInputFields,
                },
                headers: {
                    'Authorization': admin.token,
                    'Accept': 'application/json'
                }
            }).then(response => {
                setSubmitting(false);
                props.next();
            }).catch(err => {
                setSubmitting(false);
                if (err.response.status === 422) {
                    const newError = { ...error };
                    for (let i = 0; i < Object.keys(err.response.data).length; i++) {
                        newError[Object.keys(err.response.data)[i]] = Object.values(err.response.data)[i].join(", ");
                    }
                    setError(newError);
                } else if (err.response.status === 400) {
                    Swal.fire("Oops..", err.response.data, "error");
                } else {
                    localStorage.clear();
                    props.history.push(`/login`);
                }
            });
        } catch (e) {
            setSubmitting(false);
        }
    }

    const editData = () => {
        let keluarga = props.data.status_keluarga.split("|");
        let pasangan = keluarga[0].split("~");

        const newForm = { ...form };
        newForm.id = props.data.uid;
        newForm.pasangan = pasangan[0];
        newForm.nama = pasangan[1];
        newForm.gender = pasangan[2];
        newForm.tempat_lahir = pasangan[3];
        newForm.tanggal_lahir = pasangan[4];
        newForm.pendidikan = pasangan[5];
        newForm.pekerjaan = pasangan[6];
        $("#skt-pasangan").val(pasangan[0]);
        $("#skt-gender").val(pasangan[2]);
        setForm(newForm);

        const values = [...childInputFields];
        let childs;
        for (let i = 1; i < keluarga.length; i++) {
            childs = keluarga[i].split("~");
            values.push({
                nama_anak: childs[0],
                gender_anak: childs[1],
                tempat_lahir_anak: childs[2],
                tanggal_lahir_anak: childs[3],
                pendidikan_anak: childs[4],
                pekerjaan_anak: childs[5]
            });
        }
        setChildInputFields(values);
    }

    useEffect(() => {
        // jika form edit
        if (props.data.uid && props.data.status_keluarga) {
            editData();
        }
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [props.data]);

    return (<>
        <form onSubmit={handleSubmit} noValidate>
            <p className="mb-2 font-weight-bold">Bila telah menikah, lengkapilah data berikut :</p>
            <div className="table-responsive">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th style={{ minWidth: '140px' }}>Keluarga</th>
                            <th style={{ minWidth: '150px' }}>Nama</th>
                            <th style={{ minWidth: '150px' }}>L/P</th>
                            <th>Tempat & Tanggal Lahir</th>
                            <th style={{ minWidth: '150px' }}>Pendidikan</th>
                            <th style={{ minWidth: '150px' }}>Pekerjaan</th>
                        </tr>
                    </thead>
                    <tbody id="status-keluarga-table">
                        <tr>
                            <td>
                                <div className="position-relative form-group">
                                    <select name="pasangan" className={error.pasangan ? 'form-control is-invalid' : 'form-control'} required="" defaultValue={form.pasangan} onChange={handleChange} onBlur={handleChange} disabled={isSubmitting} id="skt-pasangan">
                                        <option value="">Suami/Istri</option>
                                        <option value="Suami">Suami</option>
                                        <option value="Istri">Istri</option>
                                    </select>
                                    <em className={error.pasangan ? `error invalid-feedback` : `d-none`}>{error.pasangan}</em>
                                </div>
                            </td>
                            <td>
                                <div className="position-relative form-group">
                                    <input name="nama" type="text" className={error.nama ? 'form-control is-invalid' : 'form-control'} required="" defaultValue={form.nama} onChange={handleChange} disabled={isSubmitting} maxLength="255" />
                                    <em className={error.nama ? `error invalid-feedback` : `d-none`}>{error.nama}</em>
                                </div>
                            </td>
                            <td>
                                <div className="position-relative form-group">
                                    <select name="gender" className={error.gender ? 'form-control is-invalid' : 'form-control'} required="" defaultValue={form.gender} onChange={handleChange} onBlur={handleChange} disabled={isSubmitting} id="skt-gender">
                                        <option value="">-Gender-</option>
                                        <option value="Laki-laki">Laki-laki</option>
                                        <option value="Perempuan">Perempuan</option>
                                    </select>
                                    <em className={error.gender ? `error invalid-feedback` : `d-none`}>{error.gender}</em>
                                </div>
                            </td>
                            <td>
                                <div className="position-relative form-group">
                                    <input name="tempat_lahir" type="text" className={error.tempat_lahir ? 'form-control is-invalid' : 'form-control'} required="" defaultValue={form.tempat_lahir} onChange={handleChange} disabled={isSubmitting} maxLength="255" placeholder="Tempat lahir" />
                                    <em className={error.tempat_lahir ? `error invalid-feedback` : `d-none`}>{error.tempat_lahir}</em>
                                </div>
                                <div className="position-relative form-group">
                                    <input name="tanggal_lahir" type="date" className={error.tanggal_lahir ? 'form-control is-invalid' : 'form-control'} required="" defaultValue={form.tanggal_lahir} onChange={handleChange} disabled={isSubmitting} placeholder="Tanggal lahir" />
                                    <em className={error.tanggal_lahir ? `error invalid-feedback` : `d-none`}>{error.tanggal_lahir}</em>
                                </div>
                            </td>
                            <td>
                                <div className="position-relative form-group">
                                    <input name="pendidikan" type="text" className={error.pendidikan ? 'form-control is-invalid' : 'form-control'} required="" defaultValue={form.pendidikan} onChange={handleChange} disabled={isSubmitting} maxLength="255" />
                                    <em className={error.pendidikan ? `error invalid-feedback` : `d-none`}>{error.pendidikan}</em>
                                </div>
                            </td>
                            <td>
                                <div className="position-relative form-group">
                                    <input name="pekerjaan" type="text" className={error.pekerjaan ? 'form-control is-invalid' : 'form-control'} required="" defaultValue={form.pekerjaan} onChange={handleChange} disabled={isSubmitting} maxLength="255" />
                                    <em className={error.pekerjaan ? `error invalid-feedback` : `d-none`}>{error.pekerjaan}</em>
                                </div>
                            </td>
                        </tr>
                        {childInputFields.map((d, i) => {
                        return <tr key={i}>
                            <td>
                                Anak-{i+1} <button type="button" className="btn btn-link" onClick={() => handleRemoveFields(i)} title="Delete"><i className="fa fa-times text-danger"></i></button>
                            </td>
                            <td>
                                <div className="position-relative form-group">
                                    <input name="nama_anak" type="text" className="form-control" required="" defaultValue={d.nama_anak} onChange={e => handleInputChange(i, e)} disabled={isSubmitting} maxLength="255" />
                                </div>
                            </td>
                            <td>
                                <div className="position-relative form-group">
                                    <select name="gender_anak" className="form-control" required="" defaultValue={d.gender_anak} onChange={e => handleInputChange(i, e)} onBlur={e => handleInputChange(i, e)} disabled={isSubmitting}>
                                        <option value="">-Gender-</option>
                                        <option value="Laki-laki">Laki-laki</option>
                                        <option value="Perempuan">Perempuan</option>
                                    </select>
                                </div>
                            </td>
                            <td>
                                <div className="position-relative form-group">
                                    <input name="tempat_lahir_anak" type="text" className="form-control" required="" defaultValue={d.tempat_lahir_anak} onChange={e => handleInputChange(i, e)} disabled={isSubmitting} maxLength="255" placeholder="Tempat lahir" />
                                </div>
                                <div className="position-relative form-group">
                                    <input name="tanggal_lahir_anak" type="date" className="form-control" required="" defaultValue={d.tanggal_lahir_anak} onChange={e => handleInputChange(i, e)} disabled={isSubmitting} placeholder="Tanggal lahir" />
                                </div>
                            </td>
                            <td>
                                <div className="position-relative form-group">
                                    <input name="pendidikan_anak" type="text" className="form-control" required="" defaultValue={d.pendidikan_anak} onChange={e => handleInputChange(i, e)} disabled={isSubmitting} maxLength="255" />
                                </div>
                            </td>
                            <td>
                                <div className="position-relative form-group">
                                    <input name="pekerjaan_anak" type="text" className="form-control" required="" defaultValue={d.pekerjaan_anak} onChange={e => handleInputChange(i, e)} disabled={isSubmitting} maxLength="255" />
                                </div>
                            </td>
                        </tr>
                        })}
                    </tbody>
                </table>
            </div>
            <div className="row">
                <div className="col-6">(*) Sudah meninggal dunia</div>
                <div className="col-6 text-right">
                    <button type="button" className="btn-square btn-hover-shine btn btn-sm btn-success" onClick={() => handleAddFields()}><i className="fas fa-plus"></i> New Column</button>
                </div>
            </div>
            <div className="mt-4">
                <div className="divider"></div>
                <button className="btn-hover-shine btn btn-shadow btn-primary btn-wide btn-pill float-right" type="submit" id="btn-submit2" disabled={isSubmitting}>
                    {
                        !isSubmitting ?
                        <span>Next</span>
                        :
                        <span><img src="/assets/images/ajaxloader.gif" className="img-fluid" style={{width: '18px'}} alt="" /> Loading</span>
                    }
                </button>
                <button type="button" className="btn-shadow float-right btn-wide btn-pill mr-3 btn btn-outline-secondary" onClick={() => props.prev()}>Previous</button>
            </div>
        </form>
    </>);
}

export default withRouter(StatusKeluarga);