import React, { useEffect } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import $ from 'jquery';

const Sidebar = (props) => {
    useEffect(() => {
        $('.vertical-nav-menu li a').removeClass('mm-active');
        setTimeout(() => {
            if (props.history.location.pathname.search("/admin/dashboard") > -1) {
                $("#li-dashboard").addClass('mm-active');
            } else if (props.history.location.pathname.search("/admin/users") > -1) {
                $("#li-users").addClass('mm-active');
            } else if (props.history.location.pathname.search("/admin/units") > -1) {
                $("#li-units").addClass('mm-active');
            }
        }, 200);
    });

    return (
    <div className="app-sidebar sidebar-shadow">
        <div className="app-header__logo">
            <div className="logo-src"></div>
            <div className="header__pane ml-auto">
                <div>
                    <button type="button" className="hamburger close-sidebar-btn hamburger--elastic" data-class="closed-sidebar">
                        <span className="hamburger-box">
                            <span className="hamburger-inner"></span>
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <div className="app-header__mobile-menu">
            <div>
                <button type="button" className="hamburger hamburger--elastic mobile-toggle-nav">
                    <span className="hamburger-box">
                        <span className="hamburger-inner"></span>
                    </span>
                </button>
            </div>
        </div>
        <div className="app-header__menu">
            <span>
                <button type="button" className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                    <span className="btn-icon-wrapper">
                        <i className="fa fa-ellipsis-v fa-w-6"></i>
                    </span>
                </button>
            </span>
        </div>
        <div className="scrollbar-sidebar">
            <div className="app-sidebar__inner">
                <ul className="vertical-nav-menu">
                    <li className="app-sidebar__heading">Menu</li>
                    <li>
                        <NavLink to="/admin/dashboard" id="li-dashboard">
                            <i className="metismenu-icon pe-7s-rocket"></i>
                            Dashboard
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/admin/users" id="li-users">
                            <i className="metismenu-icon pe-7s-users"></i>
                            Data Karyawan
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/admin/units" id="li-units">
                            <i className="metismenu-icon pe-7s-network"></i>
                            Data Unit
                        </NavLink>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    );
}

export default withRouter(Sidebar);