import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import isEmail from 'validator/lib/isEmail';
import DatePicker from 'react-date-picker';
import $ from 'jquery';
import { AdminContext } from '../../../components/AdminContext';
import refreshToken from '../../../configs/refreshToken';

const IdentitasPribadi = (props) => {
    const { admin } = useContext(AdminContext);

    const [form, setForm] = useState({
        id: '',
        unit: '',
        nama: '',
        email: '',
        password: '',
        nik: '',
        nidn: '',
        tgl_gabung: new Date(),
        status_karyawan: '',
        jabatan_awal: '',
        jabatan_sekarang: '',
        gender: '',
        tempat_lahir: '',
        tanggal_lahir: new Date(),
        alamat_saat_ini: '',
        alamat_ktp: '',
        telp: '',
        handphone: '',
        agama: '',
        kewarganegaraan: '',
        suku: '',
        no_ktp: '',
        tempat_masa_ktp: '',
        no_sim_a: '',
        tempat_masa_sim_a: '',
        no_sim_c: '',
        tempat_masa_sim_c: '',
        npwp: '',
        no_passport: '',
        tgl_terbit_passport: new Date(),
        no_bpjs_tenagakerja: '',
        no_bpjs_kesehatan: '',
    });
    const [error, setError] = useState({
        unit: '',
        nama: '',
        email: '',
        password: '',
        nik: '',
        nidn: '',
        tgl_gabung: '',
        status_karyawan: '',
        jabatan_awal: '',
        jabatan_sekarang: '',
        gender: '',
        tempat_lahir: '',
        tanggal_lahir: '',
        alamat_saat_ini: '',
        alamat_ktp: '',
        telp: '',
        handphone: '',
        agama: '',
        kewarganegaraan: '',
        suku: '',
        no_ktp: '',
        tempat_masa_ktp: '',
        no_sim_a: '',
        tempat_masa_sim_a: '',
        no_sim_c: '',
        tempat_masa_sim_c: '',
        npwp: '',
        no_passport: '',
        tgl_terbit_passport: '',
        no_bpjs_tenagakerja: '',
        no_bpjs_kesehatan: '',
    });
    const [bornDate, setBornDate] = useState(new Date());
    const [joinDate, setJoinDate] = useState(new Date());
    const [passportDate, setPassportDate] = useState(new Date())
    const [isSubmitting, setSubmitting] = useState(false);
    const [edit, setEdit] = useState(false);
    const [units, setUnits] = useState([]);
    const agama = ['Islam', 'Katolik', 'Protestan', 'Hindu', 'Budha', 'Konghucu', 'Lainnya'];

    const handleChange = e => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
        setError({
            ...error,
            [e.target.name]: ''
        });
    }

    const handleBornDate = val => {
        setBornDate(val);

        const newError = { ...error };
        newError.tanggal_lahir = '';
        setError(newError);
    }

    const handleJoinDate = val => {
        setJoinDate(val);

        const newError = { ...error };
        newError.tgl_gabung = '';
        setError(newError);
    }

    const handlePassportDate = val => {
        setPassportDate(val);

        const newError = { ...error };
        newError.tgl_terbit_passport = '';
        setError(newError);
    }

    const validate = () => {
        const newError = { ...error };

        if (!form.unit) {
            newError.unit = 'Unit wajib diisi';
        } else {
            newError.unit = '';
        }

        if (!form.nama) {
            newError.nama = 'Nama Lengkap wajib diisi';
        } else {
            newError.nama = '';
        }

        if (!form.email) {
            newError.email = 'Email wajib diisi';
        } else if (form.email && !isEmail(form.email)) {
            newError.email = "Email tidak valid";
        } else {
            newError.email = '';
        }

        if (!form.nik) {
            newError.nik = 'NIK wajib diisi';
        } else {
            newError.nik = '';
        }

        if (!form.gender) {
            newError.gender = 'Gender wajib diisi';
        } else {
            newError.gender = '';
        }

        if (!edit && !form.password) {
            newError.password = 'Password wajib diisi';
        } else {
            newError.password = '';
        }

        return newError;
    }

    const handleSubmit = async e => {
        e.preventDefault();
        const findErrors = validate();

        let body = $("html, body");
        body.stop().animate({scrollTop:0}, 1000, 'swing');

        if (Object.values(findErrors).some(err => err !== '')) {
            setError(findErrors);
        } else {
            try {
                setSubmitting(true);

                let tahun1 = joinDate.getFullYear().toString();
                let bulan1 = (joinDate.getMonth()+1).toString();
                let tanggal1 = joinDate.getDate().toString();
                let join_date;
                bulan1 = bulan1.padStart(2, '0');
                tanggal1 = tanggal1.padStart(2, '0');
                join_date = tahun1 + '-' + bulan1 + '-' + tanggal1;

                let tahun2 = bornDate.getFullYear().toString();
                let bulan2 = (bornDate.getMonth()+1).toString();
                let tanggal2 = bornDate.getDate().toString();
                let born_date;
                bulan2 = bulan2.padStart(2, '0');
                tanggal2 = tanggal2.padStart(2, '0');
                born_date = tahun2 + '-' + bulan2 + '-' + tanggal2;

                let tahun3 = passportDate.getFullYear().toString();
                let bulan3 = (passportDate.getMonth()+1).toString();
                let tanggal3 = passportDate.getDate().toString();
                let passport_date;
                bulan3 = bulan3.padStart(2, '0');
                tanggal3 = tanggal3.padStart(2, '0');
                passport_date = tahun3 + '-' + bulan3 + '-' + tanggal3;

                await axios({
                    method: 'post',
                    url: `${process.env.REACT_APP_API_HOST}/users/post-data-users/identitas-pribadi`,
                    responseType: 'json',
                    data: {
                        id: (localStorage.getItem('uid')) ? localStorage.getItem('uid') : form.id,
                        unit: form.unit,
                        nama: form.nama,
                        email: form.email,
                        password: form.password,
                        nik: form.nik,
                        nidn: form.nidn,
                        tgl_gabung: join_date,
                        status_karyawan: form.status_karyawan,
                        jabatan_awal: form.jabatan_awal,
                        jabatan_sekarang: form.jabatan_sekarang,
                        gender: form.gender,
                        tempat_lahir: form.tempat_lahir,
                        tanggal_lahir: born_date,
                        alamat_saat_ini: form.alamat_saat_ini,
                        alamat_ktp: form.alamat_ktp,
                        telp: form.telp,
                        handphone: form.handphone,
                        agama: form.agama,
                        kewarganegaraan: form.kewarganegaraan,
                        suku: form.suku,
                        no_ktp: form.no_ktp,
                        tempat_masa_ktp: form.tempat_masa_ktp,
                        no_sim_a: form.no_sim_a,
                        tempat_masa_sim_a: form.tempat_masa_sim_a,
                        no_sim_c: form.no_sim_c,
                        tempat_masa_sim_c: form.tempat_masa_sim_c,
                        npwp: form.npwp,
                        no_passport: form.no_passport,
                        tgl_terbit_passport: passport_date,
                        no_bpjs_tenagakerja: form.no_bpjs_tenagakerja,
                        no_bpjs_kesehatan: form.no_bpjs_kesehatan
                    },
                    headers: {
                        'Authorization': admin.token,
                        'Accept': 'application/json'
                    }
                }).then(response => {
                    setSubmitting(false);
                    localStorage.setItem('uid', response.data[1]);
                    props.next();
                }).catch(err => {
                    setSubmitting(false);
                    if (err.response.status === 422) {
                        const newError = { ...error };
                        for (let i = 0; i < Object.keys(err.response.data).length; i++) {
                            newError[Object.keys(err.response.data)[i]] = Object.values(err.response.data)[i].join(", ");
                        }
                        setError(newError);
                    } else if (err.response.status === 400) {
                        Swal.fire("Oops..", err.response.data, "error");
                    } else {
                        localStorage.clear();
                        props.history.push(`/login`);
                    }
                });
            } catch (e) {
                setSubmitting(false);
            }
        }
    }

    const editData = () => {
        const newForm = { ...form };
        newForm.id = props.data.uid;
        newForm.unit = props.data.unit_id;
        newForm.nama = props.data.name;
        newForm.email = props.data.email;
        newForm.nik = props.data.nik;
        newForm.nidn = props.data.nidn;
        newForm.status_karyawan = props.data.status;
        newForm.jabatan_awal = props.data.jabatan_awal;
        newForm.jabatan_sekarang = props.data.jabatan_sekarang;
        newForm.gender = props.data.jenis_kelamin;
        newForm.tempat_lahir = props.data.tempat_lahir;
        newForm.alamat_saat_ini = props.data.alamat_saat_ini;
        newForm.alamat_ktp = props.data.alamat_ktp;
        newForm.telp = props.data.telp;
        newForm.handphone = props.data.hp;
        newForm.agama = props.data.agama;
        newForm.kewarganegaraan = props.data.kewarganegaraan;
        newForm.suku = props.data.suku;
        newForm.no_ktp = props.data.no_ktp;
        newForm.tempat_masa_ktp = props.data.tempat_masa_ktp;
        newForm.no_sim_a = props.data.no_sim_a;
        newForm.tempat_masa_sim_a = props.data.tempat_masa_sim_a;
        newForm.no_sim_c = props.data.no_sim_c;
        newForm.tempat_masa_sim_c = props.data.tempat_masa_sim_c;
        newForm.npwp = props.data.npwp;
        newForm.no_passport = props.data.no_passport;
        newForm.no_bpjs_tenagakerja = props.data.no_bpjs_tenagakerja;
        newForm.no_bpjs_kesehatan = props.data.no_bpjs_kesehatan;
        setForm(newForm);
        setBornDate(new Date(props.data.tgl_lahir));
        setJoinDate(new Date(props.data.tgl_gabung));
        setPassportDate(new Date(props.data.tgl_terbit_passport));
        setEdit(true);

        $("#unit").val(props.data.unit_id);
        $("#gender").val(props.data.jenis_kelamin);
        $("#agama").val(props.data.agama);
        $("#btn-submit1").prop('disabled', false);
    }

    const getDataUnit = () => {
        let mounted = true;

        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_HOST}/users/get-data-unit`,
            responseType: 'json',
            data: {},
            headers: {
                'Authorization': admin.token,
                'Accept': 'application/json'
            }
        }).then(res => {
            if (mounted) {
                setUnits(res.data);

                // jika form edit
                if (props.data.uid) {
                    editData();
                }
            }
        }).catch(async err => {
            if (err?.response?.status === 403) {
                await refreshToken.refresh(process.env.REACT_APP_API_HOST, admin, 'admin');
                props.history.push(`/login`);
            } else {
                localStorage.clear();
                props.history.push(`/login`);
            }
        });
    }

    useEffect(() => {
        getDataUnit();

        /* eslint-disable react-hooks/exhaustive-deps */
    }, [props.data]);

    return (<>
        <form onSubmit={handleSubmit} noValidate>
            <div className="position-relative form-group">
                <label htmlFor="unit" className="">Unit <span className="text-danger">*</span></label>
                <select name="unit" id="unit" className={error.unit ? 'form-control is-invalid' : 'form-control'} required="" defaultValue={form.unit} onChange={handleChange}  onBlur={handleChange} disabled={isSubmitting}>
                    <option value="">-Pilih Unit-</option>
                    {
                        units.map((d, i) => {
                            return <option key={i} value={d.id}>{d.unit}</option>
                        })
                    }
                </select>
                <em className={error.unit ? `error invalid-feedback` : `d-none`}>{error.unit}</em>
            </div>
            <div className="position-relative form-group">
                <label htmlFor="nama" className="">Nama Lengkap <span className="text-danger">*</span></label>
                <input name="nama" id="nama" type="text" className={error.nama ? 'form-control is-invalid' : 'form-control'} required="" defaultValue={form.nama} onChange={handleChange} disabled={isSubmitting} maxLength="255" />
                <em className={error.nama ? `error invalid-feedback` : `d-none`}>{error.nama}</em>
            </div>
            <div className="position-relative form-group">
                <label htmlFor="email" className="">Email <span className="text-danger">*</span></label>
                <input name="email" id="email" type="email" className={error.email ? 'form-control is-invalid' : 'form-control'} required="" defaultValue={form.email} onChange={handleChange} disabled={isSubmitting} maxLength="255" />
                <em className={error.email ? `error invalid-feedback` : `d-none`}>{error.email}</em>
            </div>
            <div className="position-relative form-group">
                <label htmlFor="password" className="">Password {edit ? '(opsional)' : <span className="text-danger">*</span>}</label>
                <input name="password" id="password" type="text" className={error.password ? 'form-control is-invalid' : 'form-control'} required="" defaultValue={form.password} onChange={handleChange} disabled={isSubmitting} />
                {edit ? <small className="d-block">Kosongkan jika tidak ingin merubah password</small> : ''}
                <em className={error.password ? `error invalid-feedback d-block` : `d-none`}>{error.password}</em>
            </div>
            <div className="position-relative form-group">
                <label htmlFor="nik" className="">Nomor Induk Karyawan (NIK) <span className="text-danger">*</span></label>
                <input name="nik" id="nik" type="text" className={error.nik ? 'form-control is-invalid' : 'form-control'} required="" defaultValue={form.nik} onChange={handleChange} disabled={isSubmitting} maxLength="15" />
                <em className={error.nik ? `error invalid-feedback` : `d-none`}>{error.nik}</em>
            </div>
            <div className="position-relative form-group">
                <label htmlFor="nidn" className="">NIDN</label>
                <input name="nidn" id="nidn" type="text" className={error.nidn ? 'form-control is-invalid' : 'form-control'} required="" defaultValue={form.nidn} onChange={handleChange} disabled={isSubmitting} maxLength="15" />
                <em className={error.nidn ? `error invalid-feedback` : `d-none`}>{error.nidn}</em>
            </div>
            <div className="position-relative form-group">
                <div className="row">
                    <div className="col-sm-6">
                        <label htmlFor="tgl_gabung" className="">Tanggal Bergabung</label>
                        <DatePicker name="tgl_gabung" value={joinDate} defaultValue={joinDate} onChange={handleJoinDate} className={error.tgl_gabung ? 'form-control is-invalid' : 'form-control'} format="y-MM-dd" clearIcon="" />
                        <em className={error.tgl_gabung ? `error invalid-feedback` : `d-none`}>{error.tgl_gabung}</em>
                    </div>
                    <div className="col-sm-6 mb-3 mb-sm-0">
                        <label htmlFor="status_karyawan" className="">Status Karyawan</label>
                        <input name="status_karyawan" id="status_karyawan" type="text" className={error.status_karyawan ? 'form-control is-invalid' : 'form-control'} defaultValue={form.status_karyawan} onChange={handleChange} disabled={isSubmitting} maxLength="15" />
                        <em className={error.status_karyawan ? `error invalid-feedback` : `d-none`}>{error.status_karyawan}</em>
                    </div>
                </div>
            </div>
            <div className="position-relative form-group">
                <div className="row">
                    <div className="col-sm-6 mb-3 mb-sm-0">
                        <label htmlFor="jabatan_awal" className="">Jabatan Awal Masuk YHC</label>
                        <input name="jabatan_awal" id="jabatan_awal" type="text" className={error.jabatan_awal ? 'form-control is-invalid' : 'form-control'} defaultValue={form.jabatan_awal} onChange={handleChange} disabled={isSubmitting} maxLength="100" />
                        <em className={error.jabatan_awal ? `error invalid-feedback` : `d-none`}>{error.jabatan_awal}</em>
                    </div>
                    <div className="col-sm-6">
                        <label htmlFor="jabatan_sekarang" className="">Jabatan Sekarang</label>
                        <input name="jabatan_sekarang" id="jabatan_sekarang" type="text" className={error.jabatan_sekarang ? 'form-control is-invalid' : 'form-control'} defaultValue={form.jabatan_sekarang} onChange={handleChange} disabled={isSubmitting} maxLength="100" />
                        <em className={error.jabatan_sekarang ? `error invalid-feedback` : `d-none`}>{error.jabatan_sekarang}</em>
                    </div>
                </div>
            </div>
            <div className="position-relative form-group">
                <label htmlFor="gender" className="">Gender <span className="text-danger">*</span></label>
                <select name="gender" id="gender" className={error.gender ? 'form-control is-invalid' : 'form-control'} required="" defaultValue={form.gender} onChange={handleChange} onBlur={handleChange} disabled={isSubmitting}>
                    <option value="">-Pilih Gender-</option>
                    <option value="Laki-laki">Laki-laki</option>
                    <option value="Perempuan">Perempuan</option>
                </select>
                <em className={error.gender ? `error invalid-feedback` : `d-none`}>{error.gender}</em>
            </div>
            <div className="position-relative form-group">
                <div className="row">
                    <div className="col-sm-6 mb-3 mb-sm-0">
                        <label htmlFor="tempat_lahir" className="">Tempat Lahir</label>
                        <input name="tempat_lahir" id="tempat_lahir" type="text" className={error.tempat_lahir ? 'form-control is-invalid' : 'form-control'} defaultValue={form.tempat_lahir} onChange={handleChange} disabled={isSubmitting} maxLength="30" />
                        <em className={error.tempat_lahir ? `error invalid-feedback` : `d-none`}>{error.tempat_lahir}</em>
                    </div>
                    <div className="col-sm-6">
                        <label htmlFor="tanggal_lahir" className="">Tanggal Lahir</label>
                        <DatePicker name="tanggal_lahir" value={bornDate} defaultValue={bornDate} onChange={handleBornDate} className={error.tanggal_lahir ? 'form-control is-invalid' : 'form-control'} format="y-MM-dd" clearIcon="" />
                        <em className={error.tanggal_lahir ? `error invalid-feedback` : `d-none`}>{error.tanggal_lahir}</em>
                    </div>
                </div>
            </div>
            <div className="position-relative form-group">
                <div className="row">
                    <div className="col-sm-6 mb-3 mb-sm-0">
                        <label htmlFor="alamat_saat_ini" className="">Alamat Saat Ini</label>
                        <textarea name="alamat_saat_ini" id="alamat_saat_ini" className={error.alamat_saat_ini ? 'form-control is-invalid' : 'form-control'} defaultValue={form.alamat_saat_ini} onChange={handleChange} disabled={isSubmitting} maxLength="255">
                        </textarea>
                        <em className={error.alamat_saat_ini ? `error invalid-feedback` : `d-none`}>{error.alamat_saat_ini}</em>
                    </div>
                    <div className="col-sm-6">
                        <label htmlFor="alamat_ktp" className="">Alamat Sesuai KTP</label>
                        <textarea name="alamat_ktp" id="alamat_ktp" className={error.alamat_ktp ? 'form-control is-invalid' : 'form-control'} defaultValue={form.alamat_ktp} onChange={handleChange} disabled={isSubmitting} maxLength="255">
                        </textarea>
                        <em className={error.alamat_ktp ? `error invalid-feedback` : `d-none`}>{error.alamat_ktp}</em>
                    </div>
                </div>
            </div>
            <div className="position-relative form-group">
                <label htmlFor="telp" className="">No. Telepon</label>
                <input name="telp" id="telp" type="text" className={error.telp ? 'form-control is-invalid' : 'form-control'} defaultValue={form.telp} onChange={handleChange} disabled={isSubmitting} maxLength="15" />
                <em className={error.telp ? `error invalid-feedback` : `d-none`}>{error.telp}</em>
            </div>
            <div className="position-relative form-group">
                <label htmlFor="handphone" className="">No. Handphone</label>
                <input name="handphone" id="handphone" type="text" className={error.handphone ? 'form-control is-invalid' : 'form-control'} defaultValue={form.handphone} onChange={handleChange} disabled={isSubmitting} maxLength="13" />
                <em className={error.handphone ? `error invalid-feedback` : `d-none`}>{error.handphone}</em>
            </div>
            <div className="position-relative form-group">
                <label htmlFor="agama" className="">Agama</label>
                <select name="agama" id="agama" className={error.agama ? 'form-control is-invalid' : 'form-control'} defaultValue={form.agama} onChange={handleChange} onBlur={handleChange} disabled={isSubmitting}>
                    <option value="">-Pilih Agama-</option>
                    {
                        agama.map((a, i) => {
                            return <option key={i} value={a}>{a}</option>
                        })
                    }
                </select>
                <em className={error.agama ? `error invalid-feedback` : `d-none`}>{error.agama}</em>
            </div>
            <div className="position-relative form-group">
                <label htmlFor="kewarganegaraan" className="">Kewarganegaraan</label>
                <input name="kewarganegaraan" id="kewarganegaraan" type="text" className={error.kewarganegaraan ? 'form-control is-invalid' : 'form-control'} defaultValue={form.kewarganegaraan} onChange={handleChange} disabled={isSubmitting} maxLength="20" />
                <em className={error.kewarganegaraan ? `error invalid-feedback` : `d-none`}>{error.kewarganegaraan}</em>
            </div>
            <div className="position-relative form-group">
                <label htmlFor="suku" className="">Suku</label>
                <input name="suku" id="suku" type="text" className={error.suku ? 'form-control is-invalid' : 'form-control'} defaultValue={form.suku} onChange={handleChange} disabled={isSubmitting} maxLength="30" />
                <em className={error.suku ? `error invalid-feedback` : `d-none`}>{error.suku}</em>
            </div>
            <fieldset style={{ border: 'solid 1px #D2D2D2', padding: '10px', borderRadius: '6px' }}>
                <h4><u>KTP</u></h4>
                <div className="position-relative form-group">
                    <label htmlFor="no_ktp" className="">Nomor KTP</label>
                    <input name="no_ktp" id="no_ktp" type="text" className={error.no_ktp ? 'form-control is-invalid' : 'form-control'} defaultValue={form.no_ktp} onChange={handleChange} disabled={isSubmitting} maxLength="25" />
                    <em className={error.no_ktp ? `error invalid-feedback` : `d-none`}>{error.no_ktp}</em>
                </div>
                <div className="position-relative form-group">
                    <label htmlFor="tempat_masa_ktp" className="">Tempat & Masa Berlaku</label>
                    <input name="tempat_masa_ktp" id="tempat_masa_ktp" type="text" className={error.tempat_masa_ktp ? 'form-control is-invalid' : 'form-control'} defaultValue={form.tempat_masa_ktp} onChange={handleChange} disabled={isSubmitting} maxLength="30" />
                    <em className={error.tempat_masa_ktp ? `error invalid-feedback` : `d-none`}>{error.tempat_masa_ktp}</em>
                </div>
            </fieldset>
            <br />
            <fieldset style={{ border: 'solid 1px #D2D2D2', padding: '10px', borderRadius: '6px' }}>
                <h4><u>SIM A</u></h4>
                <div className="position-relative form-group">
                    <label htmlFor="no_sim_a" className="">Nomor SIM A</label>
                    <input name="no_sim_a" id="no_sim_a" type="text" className={error.no_sim_a ? 'form-control is-invalid' : 'form-control'} defaultValue={form.no_sim_a} onChange={handleChange} disabled={isSubmitting} maxLength="25" />
                    <em className={error.no_sim_a ? `error invalid-feedback` : `d-none`}>{error.no_sim_a}</em>
                </div>
                <div className="position-relative form-group">
                    <label htmlFor="tempat_masa_sim_a" className="">Tempat & Masa Berlaku</label>
                    <input name="tempat_masa_sim_a" id="tempat_masa_sim_a" type="text" className={error.tempat_masa_sim_a ? 'form-control is-invalid' : 'form-control'} defaultValue={form.tempat_masa_sim_a} onChange={handleChange} disabled={isSubmitting} maxLength="30" />
                    <em className={error.tempat_masa_sim_a ? `error invalid-feedback` : `d-none`}>{error.tempat_masa_sim_a}</em>
                </div>
            </fieldset>
            <br />
            <fieldset style={{ border: 'solid 1px #D2D2D2', padding: '10px', borderRadius: '6px' }}>
                <h4><u>SIM C</u></h4>
                <div className="position-relative form-group">
                    <label htmlFor="no_sim_c" className="">Nomor SIM A</label>
                    <input name="no_sim_c" id="no_sim_c" type="text" className={error.no_sim_c ? 'form-control is-invalid' : 'form-control'} defaultValue={form.no_sim_c} onChange={handleChange} disabled={isSubmitting} maxLength="25" />
                    <em className={error.no_sim_c ? `error invalid-feedback` : `d-none`}>{error.no_sim_c}</em>
                </div>
                <div className="position-relative form-group">
                    <label htmlFor="tempat_masa_sim_c" className="">Tempat & Masa Berlaku</label>
                    <input name="tempat_masa_sim_c" id="tempat_masa_sim_c" type="text" className={error.tempat_masa_sim_c ? 'form-control is-invalid' : 'form-control'} defaultValue={form.tempat_masa_sim_c} onChange={handleChange} disabled={isSubmitting} maxLength="30" />
                    <em className={error.tempat_masa_sim_c ? `error invalid-feedback` : `d-none`}>{error.tempat_masa_sim_c}</em>
                </div>
            </fieldset>
            <br />
            <div className="position-relative form-group">
                <label htmlFor="npwp" className="">NPWP</label>
                <input name="npwp" id="npwp" type="text" className={error.npwp ? 'form-control is-invalid' : 'form-control'} defaultValue={form.npwp} onChange={handleChange} disabled={isSubmitting} maxLength="20" />
                <em className={error.npwp ? `error invalid-feedback` : `d-none`}>{error.npwp}</em>
            </div>
            <div className="position-relative form-group">
                <div className="row">
                    <div className="col-sm-6 mb-3 mb-sm-0">
                        <label htmlFor="no_passport" className="">No. Passport</label>
                        <input name="no_passport" id="no_passport" type="text" className={error.no_passport ? 'form-control is-invalid' : 'form-control'} defaultValue={form.no_passport} onChange={handleChange} disabled={isSubmitting} maxLength="20" />
                        <em className={error.no_passport ? `error invalid-feedback` : `d-none`}>{error.no_passport}</em>
                    </div>
                    <div className="col-sm-6">
                        <label htmlFor="tgl_terbit_passport" className="">Tanggal Terbit Passport</label>
                        <DatePicker name="tgl_terbit_passport" value={passportDate} defaultValue={passportDate} onChange={handlePassportDate} className={error.tgl_terbit_passport ? 'form-control is-invalid' : 'form-control'} format="y-MM-dd" clearIcon="" />
                        <em className={error.tgl_terbit_passport ? `error invalid-feedback` : `d-none`}>{error.tgl_terbit_passport}</em>
                    </div>
                </div>
            </div>
            <div className="position-relative form-group">
                <label htmlFor="no_bpjs_tenagakerja" className="">No. BPJS Ketenagakerjaan</label>
                <input name="no_bpjs_tenagakerja" id="no_bpjs_tenagakerja" type="text" className={error.no_bpjs_tenagakerja ? 'form-control is-invalid' : 'form-control'} defaultValue={form.no_bpjs_tenagakerja} onChange={handleChange} disabled={isSubmitting} maxLength="25" />
                <em className={error.no_bpjs_tenagakerja ? `error invalid-feedback` : `d-none`}>{error.no_bpjs_tenagakerja}</em>
            </div>
            <div className="position-relative form-group">
                <label htmlFor="no_bpjs_kesehatan" className="">No. BPJS Kesehatan</label>
                <input name="no_bpjs_kesehatan" id="no_bpjs_kesehatan" type="text" className={error.no_bpjs_kesehatan ? 'form-control is-invalid' : 'form-control'} defaultValue={form.no_bpjs_kesehatan} onChange={handleChange} disabled={isSubmitting} maxLength="25" />
                <em className={error.no_bpjs_kesehatan ? `error invalid-feedback` : `d-none`}>{error.no_bpjs_kesehatan}</em>
            </div>
            <div className="mt-4">
                <div className="divider"></div>
                <button className="btn-hover-shine btn btn-shadow btn-primary btn-wide btn-pill float-right" type="submit" id="btn-submit1" disabled={isSubmitting}>
                    {
                        !isSubmitting ?
                        <span>Next</span>
                        :
                        <span><img src="/assets/images/ajaxloader.gif" className="img-fluid" style={{width: '18px'}} alt="" /> Loading</span>
                    }
                </button>
            </div>
        </form>
    </>);
}

export default withRouter(IdentitasPribadi);