import React from 'react';

const IdentitasPribadi = (props) => {
    return (<>
        <div className="table-responsive">
            <table className="table table-hover">
                <tbody>
                    <tr>
                        <td><b>Unit</b></td>
                        <td style={{ width: '50px' }}>:</td>
                        <td>{props.data.unit}</td>
                    </tr>
                    <tr>
                        <td><b>Nama Lengkap</b></td>
                        <td style={{ width: '50px' }}>:</td>
                        <td>{props.data.name}</td>
                    </tr>
                    <tr>
                        <td><b>Email</b></td>
                        <td style={{ width: '50px' }}>:</td>
                        <td>{props.data.email}</td>
                    </tr>
                    <tr>
                        <td><b>Nomor Induk Karyawan (NIK)</b></td>
                        <td style={{ width: '50px' }}>:</td>
                        <td>{props.data.nik}</td>
                    </tr>
                    <tr>
                        <td><b>NIDN</b></td>
                        <td style={{ width: '50px' }}>:</td>
                        <td>{props.data.nidn}</td>
                    </tr>
                    <tr>
                        <td><b>Tanggal Bergabung</b></td>
                        <td style={{ width: '50px' }}>:</td>
                        <td>{props.data.tgl_gabung}</td>
                    </tr>
                    <tr>
                        <td><b>Status Karyawan</b></td>
                        <td style={{ width: '50px' }}>:</td>
                        <td>{props.data.status}</td>
                    </tr>
                    <tr>
                        <td><b>Jabatan Awal Masuk YHC</b></td>
                        <td style={{ width: '50px' }}>:</td>
                        <td>{props.data.jabatan_awal}</td>
                    </tr>
                    <tr>
                        <td><b>Jabatan Sekarang</b></td>
                        <td style={{ width: '50px' }}>:</td>
                        <td>{props.data.jabatan_sekarang}</td>
                    </tr>
                    <tr>
                        <td><b>Jenis Kelamin</b></td>
                        <td style={{ width: '50px' }}>:</td>
                        <td>{props.data.jenis_kelamin}</td>
                    </tr>
                    <tr>
                        <td><b>Tempat, Tanggal Lahir</b></td>
                        <td style={{ width: '50px' }}>:</td>
                        <td>{props.data.tempat_lahir}, {props.data.tgl_lahir}</td>
                    </tr>
                    <tr>
                        <td><b>Alamat Saat Ini</b></td>
                        <td style={{ width: '50px' }}>:</td>
                        <td>{props.data.alamat_saat_ini}</td>
                    </tr>
                    <tr>
                        <td><b>Alamat Sesuai KTP</b></td>
                        <td style={{ width: '50px' }}>:</td>
                        <td>{props.data.alamat_ktp}</td>
                    </tr>
                    <tr>
                        <td><b>No. Telepon</b></td>
                        <td style={{ width: '50px' }}>:</td>
                        <td>{props.data.telp}</td>
                    </tr>
                    <tr>
                        <td><b>No. Handphone</b></td>
                        <td style={{ width: '50px' }}>:</td>
                        <td>{props.data.hp}</td>
                    </tr>
                    <tr>
                        <td><b>Agama</b></td>
                        <td style={{ width: '50px' }}>:</td>
                        <td>{props.data.agama}</td>
                    </tr>
                    <tr>
                        <td><b>Kewarganegaraan</b></td>
                        <td style={{ width: '50px' }}>:</td>
                        <td>{props.data.kewarganegaraan}</td>
                    </tr>
                    <tr>
                        <td><b>Suku</b></td>
                        <td style={{ width: '50px' }}>:</td>
                        <td>{props.data.suku}</td>
                    </tr>
                    <tr>
                        <td><b>No. KTP | Tempat & Masa Berlaku</b></td>
                        <td style={{ width: '50px' }}>:</td>
                        <td>{props.data.no_ktp} | {props.data.tempat_masa_ktp}</td>
                    </tr>
                    <tr>
                        <td><b>No. SIM A | Tempat & Masa Berlaku</b></td>
                        <td style={{ width: '50px' }}>:</td>
                        <td>{props.data.no_sim_a} | {props.data.tempat_masa_sim_a}</td>
                    </tr>
                    <tr>
                        <td><b>No. SIM C | Tempat & Masa Berlaku</b></td>
                        <td style={{ width: '50px' }}>:</td>
                        <td>{props.data.no_sim_c} | {props.data.tempat_masa_sim_c}</td>
                    </tr>
                    <tr>
                        <td><b>NPWP</b></td>
                        <td style={{ width: '50px' }}>:</td>
                        <td>{props.data.npwp}</td>
                    </tr>
                    <tr>
                        <td><b>No. Passport</b></td>
                        <td style={{ width: '50px' }}>:</td>
                        <td>{props.data.no_passport}</td>
                    </tr>
                    <tr>
                        <td><b>Tanggal Terbit Passport</b></td>
                        <td style={{ width: '50px' }}>:</td>
                        <td>{props.data.tgl_terbit_passport}</td>
                    </tr>
                    <tr>
                        <td><b>No. BPJS Ketenagakerjaan</b></td>
                        <td style={{ width: '50px' }}>:</td>
                        <td>{props.data.no_bpjs_tenagakerja}</td>
                    </tr>
                    <tr>
                        <td><b>No. BPJS Kesehatan</b></td>
                        <td style={{ width: '50px' }}>:</td>
                        <td>{props.data.no_bpjs_kesehatan}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className="mt-4">
            <div className="divider"></div>
            <button className="btn-hover-shine btn btn-shadow btn-primary btn-wide btn-pill float-right" type="button" onClick={() => props.next()}>Next</button>
        </div>
    </>);
}
 
export default IdentitasPribadi;