import React, { useState, useEffect } from 'react';

const StatusKeluarga = (props) => {
    const [form, setForm] = useState({
        id: '',
        pasangan: '',
        nama: '',
        gender: '',
        tempat_lahir: '',
        tanggal_lahir: '',
        pendidikan: '',
        pekerjaan: '',
    });
    const [childInputFields, setChildInputFields] = useState([]);

    useEffect(() => {
        if (props.data.uid && props.data.status_keluarga) {
            let keluarga = props.data.status_keluarga.split("|");
            let pasangan = keluarga[0].split("~");

            const newForm = { ...form };
            newForm.id = props.data.uid;
            newForm.pasangan = pasangan[0];
            newForm.nama = pasangan[1];
            newForm.gender = pasangan[2];
            newForm.tempat_lahir = pasangan[3];
            newForm.tanggal_lahir = pasangan[4];
            newForm.pendidikan = pasangan[5];
            newForm.pekerjaan = pasangan[6];
            setForm(newForm);

            const values = [...childInputFields];
            let childs;
            for (let i = 1; i < keluarga.length; i++) {
                childs = keluarga[i].split("~");
                values.push({
                    nama_anak: childs[0],
                    gender_anak: childs[1],
                    tempat_lahir_anak: childs[2],
                    tanggal_lahir_anak: childs[3],
                    pendidikan_anak: childs[4],
                    pekerjaan_anak: childs[5]
                });
            }
            setChildInputFields(values);
        }
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [props.data])

    return (<>
        <div className="table-responsive">
            <table className="table table-bordered table-hover">
                <thead>
                    <tr>
                        <th>Keluarga</th>
                        <th>Nama</th>
                        <th>L/P</th>
                        <th style={{ minWidth: '120px' }}>Tempat & Tanggal Lahir</th>
                        <th>Pendidikan</th>
                        <th>Pekerjaan</th>
                    </tr>
                </thead>
                <tbody id="status-keluarga-table">
                    <tr>
                        <td>{form.pasangan}</td>
                        <td>{form.nama}</td>
                        <td>{form.gender}</td>
                        <td>{form.tempat_lahir}</td>
                        <td>{form.pendidikan}</td>
                        <td>{form.pekerjaan}</td>
                    </tr>
                    {childInputFields.map((d, i) => {
                    return <tr key={i}>
                        <td>
                            Anak-{i+1}
                        </td>
                        <td>{d.nama_anak}</td>
                        <td>{d.gender_anak}</td>
                        <td>{d.tempat_lahir_anak}</td>
                        <td>{d.pendidikan_anak}</td>
                        <td>{d.pekerjaan_anak}</td>
                    </tr>
                    })}
                </tbody>
            </table>
        </div>
        <div className="mt-4">
            <div className="divider"></div>
            <button className="btn-hover-shine btn btn-shadow btn-primary btn-wide btn-pill float-right" type="button" onClick={() => props.next()}>Next</button>
            <button type="button" className="btn-shadow float-right btn-wide btn-pill mr-3 btn btn-outline-secondary" onClick={() => props.prev()}>Previous</button>
        </div>
    </>);
}
 
export default StatusKeluarga;