import React from 'react';
import {Switch, Route} from 'react-router-dom';
import UserContextProvider from '../../components/UserContext';
import Header from '../../layouts/users/header';
import Sidebar from '../../layouts/users/sidebar';
import Footer from '../../layouts/users/footer';
import Assets from '../../layouts/users/assets';
import UserNotFound from './404-user';
import Home from './home';
import Modals from './modals';
import UserForm from './user-form/user-form';
import UserDetail from './user-detail/user-detail';

const User = () => {
    return (
    <UserContextProvider>
        <div className="app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar">
            <Header />
            <div className="app-main">
                <Sidebar />
                <div className="app-main__outer">
                    <div className="app-main__inner">
                        <Switch>
                            <Route path="/user/dashboard" component={Home} />
                            <Route path="/user/profile/form" component={UserForm} />
                            <Route path="/user/profile/detail" component={UserDetail} />
                            <Route component={UserNotFound} />
                        </Switch>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
        <Modals />
        <Assets />
    </UserContextProvider>
    );
}
 
export default User;