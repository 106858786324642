import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import $ from 'jquery';
import { UserContext } from '../../../components/UserContext';

const RiwayatPendidikan = (props) => {
    const { user } = useContext(UserContext);
    const [isSubmitting, setSubmitting] = useState(false);
    const [pendidikanFormal, setPendidikanFormal] = useState([
        {
            tingkat: 'TK',
            nama_sekolah: '',
            jurusan: '',
            tempat: '',
            lama_pendidikan: '',
            tahun_lulus: '',
        },
        {
            tingkat: 'SD',
            nama_sekolah: '',
            jurusan: '',
            tempat: '',
            lama_pendidikan: '',
            tahun_lulus: '',
        },
        {
            tingkat: 'SLTP',
            nama_sekolah: '',
            jurusan: '',
            tempat: '',
            lama_pendidikan: '',
            tahun_lulus: '',
        },
        {
            tingkat: 'SLTA',
            nama_sekolah: '',
            jurusan: '',
            tempat: '',
            lama_pendidikan: '',
            tahun_lulus: '',
        },
        {
            tingkat: 'Diploma',
            nama_sekolah: '',
            jurusan: '',
            tempat: '',
            lama_pendidikan: '',
            tahun_lulus: '',
        },
        {
            tingkat: 'S-1',
            nama_sekolah: '',
            jurusan: '',
            tempat: '',
            lama_pendidikan: '',
            tahun_lulus: '',
        },
        {
            tingkat: 'S-2',
            nama_sekolah: '',
            jurusan: '',
            tempat: '',
            lama_pendidikan: '',
            tahun_lulus: '',
        },
        {
            tingkat: 'S-3',
            nama_sekolah: '',
            jurusan: '',
            tempat: '',
            lama_pendidikan: '',
            tahun_lulus: '',
        },
    ]);

    const [pendidikanNonformal, setPendidikanNonformal] = useState([
        {
            no: '1.',
            pelatihan: '',
            penyelenggara: '',
            tempat: '',
            tahun_lulus: '',
            sertifikat: '',
        },
        {
            no: '2.',
            pelatihan: '',
            penyelenggara: '',
            tempat: '',
            tahun_lulus: '',
            sertifikat: '',
        },
        {
            no: '3.',
            pelatihan: '',
            penyelenggara: '',
            tempat: '',
            tahun_lulus: '',
            sertifikat: '',
        },
        {
            no: '4.',
            pelatihan: '',
            penyelenggara: '',
            tempat: '',
            tahun_lulus: '',
            sertifikat: '',
        },
        {
            no: '5.',
            pelatihan: '',
            penyelenggara: '',
            tempat: '',
            tahun_lulus: '',
            sertifikat: '',
        },
        {
            no: '6.',
            pelatihan: '',
            penyelenggara: '',
            tempat: '',
            tahun_lulus: '',
            sertifikat: '',
        },
    ]);

    const handleInputChangeFormal = (index, event) => {
        const values = [...pendidikanFormal];
        switch (event.target.name) {
            case 'nama_sekolah':
                values[index].nama_sekolah = event.target.value;
                break;
            case 'jurusan':
                values[index].jurusan = event.target.value;
                break;
            case 'tempat':
                values[index].tempat = event.target.value;
                break;
            case 'lama_pendidikan':
                values[index].lama_pendidikan = event.target.value;
                break;
            default:
                values[index].tahun_lulus = event.target.value;
                break;
        }

        setPendidikanFormal(values);
    }

    const handleInputChangeNonFormal = (index, event) => {
        const values = [...pendidikanNonformal];
        switch (event.target.name) {
            case 'pelatihan':
                values[index].pelatihan = event.target.value;
                break;
            case 'penyelenggara':
                values[index].penyelenggara = event.target.value;
                break;
            case 'tempat':
                values[index].tempat = event.target.value;
                break;
            case 'tahun_lulus':
                values[index].tahun_lulus = event.target.value;
                break;
            default:
                values[index].sertifikat = event.target.value;
                break;
        }

        setPendidikanNonformal(values);
    }

    const handleSubmit = async e => {
        e.preventDefault();

        let body = $("html, body");
        body.stop().animate({scrollTop:0}, 1000, 'swing');

        try {
            setSubmitting(true);

            await axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_HOST}/users/user/post-data-user/riwayat-pendidikan`,
                responseType: 'json',
                data: {
                    email: props.email,
                    formal: pendidikanFormal,
                    nonformal: pendidikanNonformal,
                },
                headers: {
                    'Authorization': user.token,
                    'Accept': 'application/json'
                }
            }).then(response => {
                setSubmitting(false);
                props.next();
            }).catch(err => {
                setSubmitting(false);
                if (err.response.status === 400) {
                    Swal.fire("Oops..", err.response.data, "error");
                } else {
                    localStorage.clear();
                    props.history.push(`/login`);
                }
            });
        } catch (e) {
            setSubmitting(false);
        }
    }

    const editData = () => {
        let tingkat = ['TK', 'SD', 'SLTP', 'SLTA', 'Diploma', 'S-1', 'S-2', 'S-3'];
        let pendidikan_formal = props.data.pendidikan_formal.split("|");
        let pendidikan_nonformal = props.data.pendidikan_nonformal.split("|");

        const formalValues = [];
        let formal;
        for (let i = 0; i < pendidikan_formal.length; i++) {
            formal = pendidikan_formal[i].split("~");
            formalValues.push({
                tingkat: tingkat[i],
                nama_sekolah: formal[0],
                jurusan: formal[1],
                tempat: formal[2],
                lama_pendidikan: formal[3],
                tahun_lulus: formal[4],
            });
        }
        setPendidikanFormal(formalValues);

        const nonformalValues = [];
        let nonformal;
        for (let i = 0; i < pendidikan_nonformal.length; i++) {
            nonformal = pendidikan_nonformal[i].split("~");
            nonformalValues.push({
                no: i+1,
                pelatihan: nonformal[0],
                penyelenggara: nonformal[1],
                tempat: nonformal[2],
                tahun_lulus: nonformal[3],
                sertifikat: nonformal[4],
            });
        }
        setPendidikanNonformal(nonformalValues);
    }

    useEffect(() => {
        // jika form edit
        if (props.data.uid && props.data.pendidikan_formal && props.data.pendidikan_nonformal) {
            editData();
        }
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [props.data]);

    return (<>
        <form onSubmit={handleSubmit} noValidate>
            <p className="mb-2 font-weight-bold">1. Pendidikan Formal</p>
            <div className="table-responsive">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Tingkat</th>
                            <th style={{ minWidth: '150px' }}>Nama Sekolah</th>
                            <th style={{ minWidth: '150px' }}>Jurusan</th>
                            <th style={{ minWidth: '150px' }}>Tempat/Kota</th>
                            <th style={{ minWidth: '150px' }}>Lama Pendidikan</th>
                            <th style={{ minWidth: '150px' }}>Tahun Lulus</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pendidikanFormal.map((d, i) => {
                            return <tr key={i}>
                                <td>{d.tingkat}</td>
                                <td>
                                    <div className="position-relative form-group">
                                        <input name="nama_sekolah" type="text" className="form-control" required="" defaultValue={d.nama_sekolah} onChange={e => handleInputChangeFormal(i, e)} disabled={isSubmitting} maxLength="255" />
                                    </div>
                                </td>
                                <td>
                                    <div className="position-relative form-group">
                                        <input name="jurusan" type="text" className="form-control" required="" defaultValue={d.jurusan} onChange={e => handleInputChangeFormal(i, e)} disabled={isSubmitting} maxLength="255" />
                                    </div>
                                </td>
                                <td>
                                    <div className="position-relative form-group">
                                        <input name="tempat" type="text" className="form-control" required="" defaultValue={d.tempat} onChange={e => handleInputChangeFormal(i, e)} disabled={isSubmitting} maxLength="255" />
                                    </div>
                                </td>
                                <td>
                                    <div className="position-relative form-group">
                                        <input name="lama_pendidikan" type="text" className="form-control" required="" defaultValue={d.lama_pendidikan} onChange={e => handleInputChangeFormal(i, e)} disabled={isSubmitting} maxLength="255" />
                                    </div>
                                </td>
                                <td>
                                    <div className="position-relative form-group">
                                        <input name="tahun_lulus" type="text" className="form-control" required="" defaultValue={d.tahun_lulus} onChange={e => handleInputChangeFormal(i, e)} disabled={isSubmitting} maxLength="255" />
                                    </div>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            <br />
            <p className="mb-2 font-weight-bold">2. Pendidikan Non-Formal</p>
            <div className="table-responsive">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th style={{ minWidth: '150px' }}>Materi Kursus/Pelatihan</th>
                            <th style={{ minWidth: '150px' }}>Penyelenggara</th>
                            <th style={{ minWidth: '150px' }}>Tempat/Kota</th>
                            <th style={{ minWidth: '150px' }}>Tahun/Lama Kursus</th>
                            <th style={{ minWidth: '150px' }}>Sertifikat</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pendidikanNonformal.map((d, i) => {
                            return <tr key={i}>
                                <td>{d.no}.</td>
                                <td>
                                    <div className="position-relative form-group">
                                        <input name="pelatihan" type="text" className="form-control" required="" defaultValue={d.pelatihan} onChange={e => handleInputChangeNonFormal(i, e)} disabled={isSubmitting} maxLength="255" />
                                    </div>
                                </td>
                                <td>
                                    <div className="position-relative form-group">
                                        <input name="penyelenggara" type="text" className="form-control" required="" defaultValue={d.penyelenggara} onChange={e => handleInputChangeNonFormal(i, e)} disabled={isSubmitting} maxLength="255" />
                                    </div>
                                </td>
                                <td>
                                    <div className="position-relative form-group">
                                        <input name="tempat" type="text" className="form-control" required="" defaultValue={d.tempat} onChange={e => handleInputChangeNonFormal(i, e)} disabled={isSubmitting} maxLength="255" />
                                    </div>
                                </td>
                                <td>
                                    <div className="position-relative form-group">
                                        <input name="tahun_lulus" type="text" className="form-control" required="" defaultValue={d.tahun_lulus} onChange={e => handleInputChangeNonFormal(i, e)} disabled={isSubmitting} maxLength="255" />
                                    </div>
                                </td>
                                <td>
                                    <div className="position-relative form-group">
                                        <input name="sertifikat" type="text" className="form-control" required="" defaultValue={d.sertifikat} onChange={e => handleInputChangeNonFormal(i, e)} disabled={isSubmitting} maxLength="255" />
                                    </div>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            <div className="mt-4">
                <div className="divider"></div>
                <button className="btn-hover-shine btn btn-shadow btn-primary btn-wide btn-pill float-right" type="submit" id="btn-submit3" disabled={isSubmitting}>
                    {
                        !isSubmitting ?
                        <span>Next</span>
                        :
                        <span><img src="/assets/images/ajaxloader.gif" className="img-fluid" style={{width: '18px'}} alt="" /> Loading</span>
                    }
                </button>
                <button type="button" className="btn-shadow float-right btn-wide btn-pill mr-3 btn btn-outline-secondary" onClick={() => props.prev()}>Previous</button>
            </div>
        </form>
    </>);
}

export default withRouter(RiwayatPendidikan);