import React from 'react';
import {Switch, Route} from 'react-router-dom';
import AdminContextProvider from '../../components/AdminContext';
import Header from '../../layouts/admin/header';
import Sidebar from '../../layouts/admin/sidebar';
import Footer from '../../layouts/admin/footer';
import Assets from '../../layouts/admin/assets';
import AdminNotFound from './404-admin';
import Home from './home';
import Units from './units';
import Users from './users';
import Modals from './modals';
import UsersForm from './users-form/users-form';
import UsersDetail from './users-detail/users-detail';

const Admin = () => {
    return (
    <AdminContextProvider>
        <div className="app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar">
            <Header />
            <div className="app-main">
                <Sidebar />
                <div className="app-main__outer">
                    <div className="app-main__inner">
                        <Switch>
                            <Route path="/admin/dashboard" component={Home} />
                            <Route path="/admin/units" component={Units} />
                            <Route path="/admin/users" exact component={Users} />
                            <Route path="/admin/users/form/:id?" component={UsersForm} />
                            <Route path="/admin/users/detail/:id" component={UsersDetail} />
                            <Route component={AdminNotFound} />
                        </Switch>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
        <Modals />
        <Assets />
    </AdminContextProvider>
    );
}
 
export default Admin;