import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import $ from 'jquery';
import { UserContext } from '../../../components/UserContext';

const RiwayatPekerjaan = (props) => {
    const { user } = useContext(UserContext);
    const [isSubmitting, setSubmitting] = useState(false);

    const [pekerjaan, setPekerjaan] = useState([
        {
            no: '1.',
            tahun: '',
            nama_alamat: '',
            bidang_usaha: '',
            jabatan_terakhir: '',
            gaji_terakhir: '',
            alasan_berhenti: '',
        },
        {
            no: '2.',
            tahun: '',
            nama_alamat: '',
            bidang_usaha: '',
            jabatan_terakhir: '',
            gaji_terakhir: '',
            alasan_berhenti: '',
        },
        {
            no: '3.',
            tahun: '',
            nama_alamat: '',
            bidang_usaha: '',
            jabatan_terakhir: '',
            gaji_terakhir: '',
            alasan_berhenti: '',
        },
        {
            no: '4.',
            tahun: '',
            nama_alamat: '',
            bidang_usaha: '',
            jabatan_terakhir: '',
            gaji_terakhir: '',
            alasan_berhenti: '',
        },
        {
            no: '5.',
            tahun: '',
            nama_alamat: '',
            bidang_usaha: '',
            jabatan_terakhir: '',
            gaji_terakhir: '',
            alasan_berhenti: '',
        },
        {
            no: '6.',
            tahun: '',
            nama_alamat: '',
            bidang_usaha: '',
            jabatan_terakhir: '',
            gaji_terakhir: '',
            alasan_berhenti: '',
        },
    ]);

    const handleInputChange = (index, event) => {
        const values = [...pekerjaan];
        switch (event.target.name) {
            case 'tahun':
                values[index].tahun = event.target.value;
                break;
            case 'nama_alamat':
                values[index].nama_alamat = event.target.value;
                break;
            case 'bidang_usaha':
                values[index].bidang_usaha = event.target.value;
                break;
            case 'jabatan_terakhir':
                values[index].jabatan_terakhir = event.target.value;
                break;
            case 'gaji_terakhir':
                values[index].gaji_terakhir = event.target.value;
                break;
            default:
                values[index].alasan_berhenti = event.target.value;
                break;
        }

        setPekerjaan(values);
    }

    const handleSubmit = async e => {
        e.preventDefault();

        let body = $("html, body");
        body.stop().animate({scrollTop:0}, 1000, 'swing');

        try {
            setSubmitting(true);

            await axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_HOST}/users/user/post-data-user/riwayat-pekerjaan`,
                responseType: 'json',
                data: {
                    email: props.email,
                    riwayat_pekerjaan: pekerjaan,
                },
                headers: {
                    'Authorization': user.token,
                    'Accept': 'application/json'
                }
            }).then(response => {
                setSubmitting(false);
                props.next();
            }).catch(err => {
                setSubmitting(false);
                if (err.response.status === 400) {
                    Swal.fire("Oops..", err.response.data, "error");
                } else {
                    localStorage.clear();
                    props.history.push(`/login`);
                }
            });
        } catch (e) {
            setSubmitting(false);
        }
    }

    const editData = () => {
        let riwayatPekerjaan = props.data.riwayat_pekerjaan.split("|");

        const pekerjaanValues = [];
        let riwPekerjaan;
        for (let i = 0; i < riwayatPekerjaan.length; i++) {
            riwPekerjaan = riwayatPekerjaan[i].split("~");
            pekerjaanValues.push({
                no: (i+1) + '.',
                tahun: riwPekerjaan[0],
                nama_alamat: riwPekerjaan[1],
                bidang_usaha: riwPekerjaan[2],
                jabatan_terakhir: riwPekerjaan[3],
                gaji_terakhir: riwPekerjaan[4],
                alasan_berhenti: riwPekerjaan[5],
            });
        }
        setPekerjaan(pekerjaanValues);
    }

    useEffect(() => {
        // jika form edit
        if (props.data.uid && props.data.riwayat_pekerjaan) {
            editData();
        }
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [props.data]);

    return (<>
        <form onSubmit={handleSubmit} noValidate>
            <p className="mb-2 font-weight-bold">Riwayat Pekerjaan dimulai dari pekerjaan yang terakhir :</p>
            <div className="table-responsive">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th style={{ minWidth: '150px' }}>Tahun/Periode</th>
                            <th style={{ minWidth: '150px' }}>Nama & Alamat Perusahaan</th>
                            <th style={{ minWidth: '150px' }}>Bidang Usaha</th>
                            <th style={{ minWidth: '150px' }}>Jabatan Terakhir</th>
                            <th style={{ minWidth: '150px' }}>Gaji Terakhir</th>
                            <th style={{ minWidth: '150px' }}>Alasan Berhenti</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pekerjaan.map((d, i) => {
                            return <tr key={i}>
                                <td>{d.no}</td>
                                <td>
                                    <div className="position-relative form-group">
                                        <input name="tahun" type="text" className="form-control" required="" defaultValue={d.tahun} onChange={e => handleInputChange(i, e)} disabled={isSubmitting} maxLength="255" />
                                    </div>
                                </td>
                                <td>
                                    <div className="position-relative form-group">
                                        <input name="nama_alamat" type="text" className="form-control" required="" defaultValue={d.nama_alamat} onChange={e => handleInputChange(i, e)} disabled={isSubmitting} maxLength="255" />
                                    </div>
                                </td>
                                <td>
                                    <div className="position-relative form-group">
                                        <input name="bidang_usaha" type="text" className="form-control" required="" defaultValue={d.bidang_usaha} onChange={e => handleInputChange(i, e)} disabled={isSubmitting} maxLength="255" />
                                    </div>
                                </td>
                                <td>
                                    <div className="position-relative form-group">
                                        <input name="jabatan_terakhir" type="text" className="form-control" required="" defaultValue={d.jabatan_terakhir} onChange={e => handleInputChange(i, e)} disabled={isSubmitting} maxLength="255" />
                                    </div>
                                </td>
                                <td>
                                    <div className="position-relative form-group">
                                        <input name="gaji_terakhir" type="text" className="form-control" required="" defaultValue={d.gaji_terakhir} onChange={e => handleInputChange(i, e)} disabled={isSubmitting} maxLength="255" />
                                    </div>
                                </td>
                                <td>
                                    <div className="position-relative form-group">
                                        <input name="alasan_berhenti" type="text" className="form-control" required="" defaultValue={d.alasan_berhenti} onChange={e => handleInputChange(i, e)} disabled={isSubmitting} maxLength="255" />
                                    </div>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            <div className="mt-4">
                <div className="divider"></div>
                <button className="btn-hover-shine btn btn-shadow btn-primary btn-wide btn-pill float-right" type="submit" id="btn-submit4" disabled={isSubmitting}>
                    {
                        !isSubmitting ?
                        <span>Next</span>
                        :
                        <span><img src="/assets/images/ajaxloader.gif" className="img-fluid" style={{width: '18px'}} alt="" /> Loading</span>
                    }
                </button>
                <button type="button" className="btn-shadow float-right btn-wide btn-pill mr-3 btn btn-outline-secondary" onClick={() => props.prev()}>Previous</button>
            </div>
        </form>
    </>);
}
 
export default withRouter(RiwayatPekerjaan);