import React, { useState, useEffect } from 'react';

const RiwwayatPendidikan = (props) => {
    const [pendidikanFormal, setPendidikanFormal] = useState([
        {
            tingkat: 'TK',
            nama_sekolah: '',
            jurusan: '',
            tempat: '',
            lama_pendidikan: '',
            tahun_lulus: '',
        },
        {
            tingkat: 'SD',
            nama_sekolah: '',
            jurusan: '',
            tempat: '',
            lama_pendidikan: '',
            tahun_lulus: '',
        },
        {
            tingkat: 'SLTP',
            nama_sekolah: '',
            jurusan: '',
            tempat: '',
            lama_pendidikan: '',
            tahun_lulus: '',
        },
        {
            tingkat: 'SLTA',
            nama_sekolah: '',
            jurusan: '',
            tempat: '',
            lama_pendidikan: '',
            tahun_lulus: '',
        },
        {
            tingkat: 'Diploma',
            nama_sekolah: '',
            jurusan: '',
            tempat: '',
            lama_pendidikan: '',
            tahun_lulus: '',
        },
        {
            tingkat: 'S-1',
            nama_sekolah: '',
            jurusan: '',
            tempat: '',
            lama_pendidikan: '',
            tahun_lulus: '',
        },
        {
            tingkat: 'S-2',
            nama_sekolah: '',
            jurusan: '',
            tempat: '',
            lama_pendidikan: '',
            tahun_lulus: '',
        },
        {
            tingkat: 'S-3',
            nama_sekolah: '',
            jurusan: '',
            tempat: '',
            lama_pendidikan: '',
            tahun_lulus: '',
        },
    ]);

    const [pendidikanNonformal, setPendidikanNonformal] = useState([
        {
            no: '1.',
            pelatihan: '',
            penyelenggara: '',
            tempat: '',
            tahun_lulus: '',
            sertifikat: '',
        },
        {
            no: '2.',
            pelatihan: '',
            penyelenggara: '',
            tempat: '',
            tahun_lulus: '',
            sertifikat: '',
        },
        {
            no: '3.',
            pelatihan: '',
            penyelenggara: '',
            tempat: '',
            tahun_lulus: '',
            sertifikat: '',
        },
        {
            no: '4.',
            pelatihan: '',
            penyelenggara: '',
            tempat: '',
            tahun_lulus: '',
            sertifikat: '',
        },
        {
            no: '5.',
            pelatihan: '',
            penyelenggara: '',
            tempat: '',
            tahun_lulus: '',
            sertifikat: '',
        },
        {
            no: '6.',
            pelatihan: '',
            penyelenggara: '',
            tempat: '',
            tahun_lulus: '',
            sertifikat: '',
        },
    ]);

    useEffect(() => {
        if (props.data.uid && props.data.pendidikan_formal && props.data.pendidikan_nonformal) {
            let tingkat = ['TK', 'SD', 'SLTP', 'SLTA', 'Diploma', 'S-1', 'S-2', 'S-3'];
            let pendidikan_formal = props.data.pendidikan_formal.split("|");
            let pendidikan_nonformal = props.data.pendidikan_nonformal.split("|");

            const formalValues = [];
            let formal;
            for (let i = 0; i < pendidikan_formal.length; i++) {
                formal = pendidikan_formal[i].split("~");
                formalValues.push({
                    tingkat: tingkat[i],
                    nama_sekolah: formal[0],
                    jurusan: formal[1],
                    tempat: formal[2],
                    lama_pendidikan: formal[3],
                    tahun_lulus: formal[4],
                });
            }
            setPendidikanFormal(formalValues);

            const nonformalValues = [];
            let nonformal;
            for (let i = 0; i < pendidikan_nonformal.length; i++) {
                nonformal = pendidikan_nonformal[i].split("~");
                nonformalValues.push({
                    no: i+1,
                    pelatihan: nonformal[0],
                    penyelenggara: nonformal[1],
                    tempat: nonformal[2],
                    tahun_lulus: nonformal[3],
                    sertifikat: nonformal[4],
                });
            }
            setPendidikanNonformal(nonformalValues);
        }
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [props.data]);

    return (<>
        <p className="mb-2 font-weight-bold">1. Pendidikan Formal</p>
        <div className="table-responsive">
            <table className="table table-bordered table-hover">
                <thead>
                    <tr>
                        <th>Tingkat</th>
                        <th>Nama Sekolah</th>
                        <th>Jurusan</th>
                        <th>Tempat/Kota</th>
                        <th>Lama Pendidikan</th>
                        <th>Tahun Lulus</th>
                    </tr>
                </thead>
                <tbody>
                    {pendidikanFormal.map((d, i) => {
                        return <tr key={i}>
                            <td>{d.tingkat}</td>
                            <td>{d.nama_sekolah}</td>
                            <td>{d.jurusan}</td>
                            <td>{d.tempat}</td>
                            <td>{d.lama_pendidikan}</td>
                            <td>{d.tahun_lulus}</td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
        <br />
        <p className="mb-2 font-weight-bold">2. Pendidikan Non-Formal</p>
        <div className="table-responsive">
            <table className="table table-bordered table-hover">
                <thead>
                    <tr>
                        <th>No.</th>
                        <th>Materi Kursus/Pelatihan</th>
                        <th>Penyelenggara</th>
                        <th>Tempat/Kota</th>
                        <th>Tahun/Lama Kursus</th>
                        <th>Sertifikat</th>
                    </tr>
                </thead>
                <tbody>
                    {pendidikanNonformal.map((d, i) => {
                        return <tr key={i}>
                            <td>{d.no}.</td>
                            <td>{d.pelatihan}</td>
                            <td>{d.penyelenggara}</td>
                            <td>{d.tempat}</td>
                            <td>{d.tahun_lulus}</td>
                            <td>{d.sertifikat}</td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
        <div className="mt-4">
            <div className="divider"></div>
            <button className="btn-hover-shine btn btn-shadow btn-primary btn-wide btn-pill float-right" type="button" onClick={() => props.next()}>Next</button>
            <button type="button" className="btn-shadow float-right btn-wide btn-pill mr-3 btn btn-outline-secondary" onClick={() => props.prev()}>Previous</button>
        </div>
    </>);
}
 
export default RiwwayatPendidikan;