import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import axios from 'axios';
import $ from 'jquery';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from "react-toastify";
import { AdminContext } from '../../components/AdminContext';
import refreshToken from '../../configs/refreshToken';
import 'react-toastify/dist/ReactToastify.css';

const Modals = (props) => {
    const { admin } = useContext(AdminContext);
    const [data, setData] = useState([]);
    const [value, setValue] = useState([]);
    const [isSubmitting, setSubmitting] = useState(false);

    const animatedComponents = makeAnimated();

    const handleInputChange = (value) => {
        setValue(value);
    }

    const handleSubmit = async e => {
        e.preventDefault();

        if (value.length === 0) {
            toast.warn("Role tidak boleh kosong.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            try {
                setSubmitting(true);

                await axios({
                    method: 'post',
                    url: `${process.env.REACT_APP_API_HOST}/users/post-user-roles`,
                    responseType: 'json',
                    data: {
                        uid: $('#role-uid').val(),
                        data: value
                    },
                    headers: {
                        'Authorization': admin.token,
                        'Accept': 'application/json'
                    }
                }).then(response => {
                    setSubmitting(false);
                    Swal.fire("Sukses", response.data, "success");
                }).catch(err => {
                    setSubmitting(false);
                    if (err.response.status === 401) {
                        localStorage.clear();
                        props.history.push(`/login`);
                    } else if (err.response.status === 400) {
                        Swal.fire("Oops..", "Simpan data gagal!<br />"+err?.response?.data, "error");
                    }
                });
            } catch (e) {
                setSubmitting(false);
            }
        }
    }

    const initValue = () => {
        let roles = $('#role-values').val().split("|");
        let values = [];
        for (let i = 0; i < roles.length; i++) {
            for (let x = 0; x < data.length; x++) {
                if (data[x].value === parseInt(roles[i])) {
                    values.push(data[x]);
                }
            }
        }

        setTimeout(() => {
            setValue(values);
        }, 500);
    }

    useEffect(() => {
        let mounted = true;

        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_HOST}/users/get-user-roles`,
            responseType: 'json',
            data: {},
            headers: {
                'Authorization': admin.token,
                'Accept': 'application/json'
            }
        }).then(res => {
            if (mounted) {
                let datas = [];
                for (let i = 0; i < res.data.length; i++) {
                    datas.push(
                        {value: res.data[i].id, label: res.data[i].role}
                    );
                }
                setData(datas);
            }
        }).catch(async err => {
            if (err?.response?.status === 403) {
                await refreshToken.refresh(process.env.REACT_APP_API_HOST, admin, 'admin');
                props.history.push(`/login`);
            } else {
                localStorage.clear();
                props.history.push(`/login`);
            }
        });
        /* eslint-disable react-hooks/exhaustive-deps */
    }, []);

    return (<>
        <button type="button" className="d-none" data-toggle="modal" data-target="#detailModal" id="modalDetail">showModal</button>
        <div className="modal fade" id="detailModal" tabIndex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Detail Data Karyawan</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <table className="table table-hover">
                            <tbody>
                                <tr>
                                    <td><b>Unit</b></td>
                                    <td width="10">:</td>
                                    <td className="td-unit">...</td>
                                </tr>
                                <tr>
                                    <td><b>NIK</b></td>
                                    <td>:</td>
                                    <td className="td-nik">...</td>
                                </tr>
                                <tr>
                                    <td><b>Nama Lengkap</b></td>
                                    <td>:</td>
                                    <td className="td-nama">...</td>
                                </tr>
                                <tr>
                                    <td><b>Nama Panggilan</b></td>
                                    <td>:</td>
                                    <td className="td-nickname">...</td>
                                </tr>
                                <tr>
                                    <td><b>Email</b></td>
                                    <td>:</td>
                                    <td className="td-email">...</td>
                                </tr>
                                <tr>
                                    <td><b>Gender</b></td>
                                    <td>:</td>
                                    <td className="td-gender">...</td>
                                </tr>
                                <tr>
                                    <td><b>Tempat, Tanggal Lahir</b></td>
                                    <td>:</td>
                                    <td className="td-lahir">...</td>
                                </tr>
                                <tr>
                                    <td><b>Alamat Saat Ini</b></td>
                                    <td>:</td>
                                    <td className="td-alamat1">...</td>
                                </tr>
                                <tr>
                                    <td><b>Alamat Permanen</b></td>
                                    <td>:</td>
                                    <td className="td-alamat2">...</td>
                                </tr>
                                <tr>
                                    <td><b>No. Handphone</b></td>
                                    <td>:</td>
                                    <td className="td-hp">...</td>
                                </tr>
                                <tr>
                                    <td><b>Agama</b></td>
                                    <td>:</td>
                                    <td className="td-agama">...</td>
                                </tr>
                                <tr>
                                    <td><b>Kewarganegaraan</b></td>
                                    <td>:</td>
                                    <td className="td-warganegara">...</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary btn-hover-shine btn-shadow" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>

        <button type="button" className="d-none" data-toggle="modal" data-target="#userRoleModal" id="modalRoles" onClick={initValue}>showModal</button>
        <div className="modal fade" id="userRoleModal" tabIndex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Set User Role</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="modal-body">
                            <div className="form-group">
                                <label>Set Role <b><span id="role-nama-user"></span></b></label>
                                <Select
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    isMulti
                                    options={data}
                                    onChange={handleInputChange}
                                    value={value}
                                    isDisabled={isSubmitting}
                                    />
                                <input type="hidden" id="role-values" />
                                <input type="hidden" id="role-uid" />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary btn-hover-shine btn-shadow" disabled={isSubmitting}>
                                {
                                    !isSubmitting ?
                                    <span>Simpan</span>
                                    :
                                    <span><img src="/assets/images/ajaxloader.gif" className="img-fluid" style={{width: '18px'}} alt="" /> Loading</span>
                                }
                            </button>
                            <button type="button" className="btn btn-secondary btn-hover-shine btn-shadow" data-dismiss="modal" disabled={isSubmitting}>Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <ToastContainer />
    </>);
}

export default withRouter(Modals);