import React, { createContext } from 'react';

export const AdminContext = createContext();

const AdminContextProvider = (props) => {
    const userInfo = {
        admin: JSON.parse(localStorage.getItem('admin-token'))
    }

    const getMimetype = (signature) => {
        switch (signature) {
            case '89504E47':
                return 'image/png'
            case '47494638':
                return 'image/gif'
            case '25504446':
                return 'application/pdf'
            case 'FFD8FFDB':
            case 'FFD8FFE0':
            case 'FFD8FFE1':
                return 'image/jpeg'
            case '3C737667':
                return 'image/svg+xml'
            case '504B0304':
                return 'application/zip'
            default:
                return 'Unknown filetype'
        }
    }

    return (
        <AdminContext.Provider value={{ ...userInfo, getMimetype }}>
            {props.children}
        </AdminContext.Provider>
    );
}
 
export default AdminContextProvider;