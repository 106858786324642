import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import $ from 'jquery';
import Swal from 'sweetalert2';
import { AdminContext } from '../../components/AdminContext';
import refreshToken from '../../configs/refreshToken';
import bsCustomFileInput from 'bs-custom-file-input';

const Units = (props) => {
    const { admin, getMimetype } = useContext(AdminContext);

    const [form, setForm] = useState({
        id: '',
        unit: '',
        logo: '',
        old_logo: '',
    });
    const [error, setError] = useState({
        unit: '',
        logo: '',
    });
    const [isSubmitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [edit, setEdit] = useState(false);

    const handleChange = e => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
        setError({
            ...error,
            [e.target.name]: ''
        });
    }

    const validate = () => {
        const newError = { ...error };

        if (!form.unit) {
            newError.unit = 'Unit wajib diisi';
        } else {
            newError.unit = '';
        }

        return newError;
    }

    const onChangeFile = e => {
        const newForm = { ...form };
        newForm.logo = e.target.files[0];
        setForm(newForm);

        // console.time('FileOpen');
        const file = e.target.files[0];
        if (file !== undefined) {
            const filereader = new FileReader();
            filereader.onloadend = function(evt) {
                if (evt.target.readyState === FileReader.DONE) {
                    const uint = new Uint8Array(evt.target.result);
                    let bytes = [];
                    uint.forEach((byte) => {
                        bytes.push(byte.toString(16));
                    });
                    const hex = bytes.join('').toUpperCase();
                    const newError = { ...error };

                    if (getMimetype(hex) !== 'image/png' && getMimetype(hex) !== 'image/jpeg' && getMimetype(hex) !== 'image/svg+xml') {
                        $("#form .btn-proses").prop('disabled', true);
                        newError.logo = 'File tidak valid! File hanya boleh berekstensi jpeg, jpg, svg dan png.';
                    } else {
                        $("#form .btn-proses").prop('disabled', false);
                        newError.logo = '';
                    }
                    setError(newError);
                }
                // console.timeEnd('FileOpen');
            }

            const blob = file.slice(0, 4);
            filereader.readAsArrayBuffer(blob);
        }
    }

    const handleSubmit = async e => {
        e.preventDefault();
        const findErrors = validate();

        if (Object.values(findErrors).some(err => err !== '')) {
            setError(findErrors);
        } else {
            try {
                setSubmitting(true);

                const formData = new FormData();
                formData.append('id', form.id);
                formData.append('unit', form.unit);
                formData.append('logo', form.logo);
                formData.append('old_logo', form.old_logo);

                await axios({
                    method: 'post',
                    url: `${process.env.REACT_APP_API_HOST}/users/post-data-unit`,
                    responseType: 'json',
                    data: formData,
                    headers: {
                        'Authorization': admin.token,
                        'Accept': 'application/json',
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    setSubmitting(false);
                    Swal.fire("Sukses", response.data, "success");
                    // call data
                    getData();
                    // reset form
                    resetForm();
                }).catch(err => {
                    setSubmitting(false);
                    if (err.response.status === 422) {
                        const newError = { ...error };
                        for (let i = 0; i < Object.keys(err.response.data).length; i++) {
                            newError[Object.keys(err.response.data)[i]] = Object.values(err.response.data)[i].join(", ");
                        }
                        setError(newError);
                    } else if (err.response.status === 400) {
                        Swal.fire("Oops..", "Simpan data gagal!", "error");
                    } else {
                        localStorage.clear();
                        props.history.push(`/login`);
                    }
                });
            } catch (e) {
                setSubmitting(false);
            }
        }
    }

    const editData = (id) => async e => {
        await axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_HOST}/users/edit-data-unit`,
            responseType: 'json',
            data: {
                id: id
            },
            headers: {
                'Authorization': admin.token,
                'Accept': 'application/json'
            }
        }).then(res => {
            const newForm = { ...form };
            newForm.id = res.data.id;
            newForm.unit = res.data.unit;
            newForm.old_logo = res.data.logo;
            setForm(newForm);
            setEdit(true);
        }).catch(async err => {
            if (err?.response?.status === 403) {
                await refreshToken.refresh(process.env.REACT_APP_API_HOST, admin, 'admin');
                props.history.push(`/login`);
            } else {
                localStorage.clear();
                props.history.push(`/login`);
            }
        });
    }

    const deleteData = (id, nama, logo) => e => {
        Swal.fire({
            title: 'Yakin Ingin Hapus?',
            html: "Yakin Ingin Hapus Unit <b>'"+nama+"'</b>?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, hapus!'
        }).then((result) => {
            if (result.value) {
                setLoading(true);

                axios({
                    method: 'delete',
                    url: `${process.env.REACT_APP_API_HOST}/users/delete-data-unit/${id}/${logo}`,
                    responseType: 'json',
                    data: {},
                    headers: {
                        'Authorization': admin.token,
                        'Accept': 'application/json'
                    }
                }).then(res => {
                    if (res.data === "Sukses") {
                        Swal.fire({
                            title: "Sukses!",
                            html: "Hapus data <b>'"+nama+"'</b> berhasil!",
                            icon: "success",
                            showCancelButton: false,
                            confirmButtonText: "Ok",
                        }).then((result) => {
                          getData();
                        });
                    } else {
                        Swal.fire("Oops..", "Hapus data '"+nama+"' gagal!", "error");
                        setLoading(false);
                    }
                }).catch(async err => {
                    if (err?.response?.status === 403) {
                        await refreshToken.refresh(process.env.REACT_APP_API_HOST, admin, 'admin');
                        props.history.push(`/login`);
                    } else {
                        Swal.fire("Oops..", "Terjadi kesalahan. Silakan coba lagi", "warning");
                        localStorage.clear();
                        props.history.push(`/login`);
                    }
                });
            }
        });
    }

    const getData = () => {
        let mounted = true;
        setLoading(true);

        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_HOST}/users/get-data-unit`,
            responseType: 'json',
            data: {},
            headers: {
                'Authorization': admin.token,
                'Accept': 'application/json'
            }
        }).then(res => {
            if (mounted) {
                setLoading(false);
                setData(res.data);
            }
        }).catch(async err => {
            if (err?.response?.status === 403) {
                await refreshToken.refresh(process.env.REACT_APP_API_HOST, admin, 'admin');
                props.history.push(`/login`);
            } else {
                localStorage.clear();
                props.history.push(`/login`);
            }
        });
    }

    const resetForm = () => {
        // reset form
        const newForm = { ...form };
        newForm.id = '';
        newForm.unit = '';
        setForm(newForm);
        setEdit(false);
        $('#form').trigger("reset");
    }

    useEffect(() => {
        // jika lebar window kurang dari 992px maka trigger click button close-sidebar
        if ($(window).width() < 992 && $('.mobile-toggle-nav.is-active').length > 0) {
            $('.app-header__mobile-menu .mobile-toggle-nav-left').click();
        }

        getData();
        bsCustomFileInput.init();
        /* eslint-disable react-hooks/exhaustive-deps */
    }, []);

    return (<>
        <div className="app-page-title">
            <div className="page-title-wrapper">
                <div className="page-title-heading">
                    <div className="page-title-icon">
                        <i className="pe-7s-network icon-gradient bg-happy-itmeo"></i>
                    </div>
                    <div>
                        Data Unit
                        <div className="page-title-subheading">List Data Unit di Yayasan Hasnur Centre</div>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-5">
                <div className="main-card mb-3 card">
                    <div className="card-body">
                        <h5 className="card-title">Form Unit</h5><hr />
                        <form className="" id="form" onSubmit={handleSubmit} noValidate>
                            <div className="position-relative form-group">
                                <label htmlFor="unit" className="">Nama Unit</label>
                                <input name="unit" id="unit" placeholder="Nama Unit" type="text" className={error.unit ? 'form-control is-invalid' : 'form-control'} required="" defaultValue={form.unit} onChange={handleChange} disabled={isSubmitting} />
                                <em className={error.unit ? `error invalid-feedback` : `d-none`}>{error.unit}</em>
                            </div>
                            <div className="position-relative form-group mb-4">
                                <label>Upload Logo</label>
                                <div className="custom-file">
                                    <input type="file" name="logo" className="custom-file-input" id="logo" onChange={onChangeFile} disabled={isSubmitting} accept="image/png, image/jpg, image/jpeg, image/svg+xml" />
                                    <label className="custom-file-label" htmlFor="logo">Choose file</label>
                                </div>
                                <font color="grey"><small>{form.old_logo}</small></font>
                                <span className="float-right"><small><i>Only: png, svg, jpg, jpeg</i></small></span>
                                <em className={error.logo ? `error invalid-feedback d-block` : `d-none`}>{error.logo}</em>
                            </div>
                            <hr />
                            <div className="mt-2">
                                {
                                    edit ?
                                    <button type="button" className="btn btn-link pl-0" onClick={resetForm}><i className="fa fa-arrow-left"></i> Kembali</button> : ''
                                }
                                <button className="btn-hover-shine btn btn-shadow btn-primary float-right" type="submit" disabled={isSubmitting}>
                                    {
                                        !isSubmitting ?
                                        <span>{edit ? 'Perbarui' : 'Simpan'}</span>
                                        :
                                        <span><img src="/assets/images/ajaxloader.gif" className="img-fluid" style={{width: '18px'}} alt="" /> Loading</span>
                                    }
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="col-md-7">
                <div className="main-card mb-3 card">
                    <div className="card-body">
                        <h5 className="card-title">Data Unit</h5><hr />
                        <table className="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th width="50">No.</th>
                                    <th>Unit</th>
                                    <th width="100">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !loading ?
                                    data.map((d, i) => {
                                        return <tr key={i}>
                                            <td>{i+1}.</td>
                                            <td>{d.unit}</td>
                                            <td align="center">
                                                <button type="button" className="mr-2 btn btn-sm btn-hover-shine btn-shadow btn-warning" onClick={editData(d.id)}><i className="fas fa-pencil-alt text-white"></i></button>
                                                {
                                                    (d.cek < 1) ?
                                                    <button type="button" className="mr-2 btn btn-hover-shine btn-sm btn-shadow btn-danger" onClick={deleteData(d.id, d.unit, d.logo)}><i className="fas fa-trash"></i></button>
                                                    : ''
                                                }
                                            </td>
                                        </tr>
                                    }) :
                                    <tr>
                                        <td colSpan="3" align="center"><b>Loading...</b></td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </>);
}
 
export default Units;