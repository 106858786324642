import React from 'react';
import { withRouter } from 'react-router-dom';

const Footer = () => {
    return (
    <div className="app-wrapper-footer">
        <div className="app-footer">
            <div className="app-footer__inner d-flex justify-content-center">
                <div className="text-center opacity-8">Copyright © YHC Users Management 2021</div>
            </div>
        </div>
    </div>
    );
}
 
export default withRouter(Footer);