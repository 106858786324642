import React, { useEffect } from 'react';
import $ from 'jquery';

const Assets = () => {
    useEffect(() => {
        let script = '<script src="/assets/js/demo.js"></script><script src="/assets/js/treeview.js"></script><script src="/assets/js/app.js"></script>';
        $('#dashboard-script-wrapper').html(script);
    }, []);

    return <></>
}

export default Assets;