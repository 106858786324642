import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import jwt_decode from "jwt-decode";
import { ToastContainer } from "react-toastify";
import { UserContext } from '../../../components/UserContext';
import refreshToken from '../../../configs/refreshToken';

import IdentitasPribadi from './identitas-pribadi';
import StatusKeluarga from './status-keluarga';
import RiwayatPendidikan from './riwayat-pendidikan';
import RiwayatPekerjaan from './riwayat-pekerjaan';
import LainLain from './lain-lain';

const UserDetail = (props) => {
    const { user } = useContext(UserContext);
    const  dataUser = jwt_decode(user.token).data;
    const [position, setPosition] = useState('one');
    const [aktif, setAktif] = useState('');
    const [data, setData] = useState([]);

    const setPosisi = (posisi) => e => {
        e.preventDefault();
        setPosition(posisi);
    }

    useEffect(() => {
        $(".form-wizard-custom").hide();
        switch (position) {
            case 'one':
                $("#one").show();
                setAktif('Identitas Pribadi');
                break;
            case 'two':
                $("#two").show();
                setAktif('Status Keluarga');
                break;
            case 'three':
                $("#three").show();
                setAktif('Riwayat Pendidikan');
                break;
            case 'four':
                $("#four").show();
                setAktif('Riwayat Pekerjaan');
                break;
            case 'five':
                $("#five").show();
                setAktif('Lain-lain');
                break;
            default:
                $("#one").show();
                setAktif('Identitas Pribadi');
                break;
        }
    }, [position]);

    useEffect(() => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_HOST}/users/user/get-data-user`,
            responseType: 'json',
            data: {
                email: dataUser.email
            },
            headers: {
                'Authorization': user.token,
                'Accept': 'application/json'
            }
        }).then(res => {
            setData(res.data);
        }).catch(async err => {
            if (err?.response?.status === 403) {
                await refreshToken.refresh(process.env.REACT_APP_API_HOST, user, 'user');
                props.history.push(`/login`);
            } else {
                localStorage.clear();
                props.history.push(`/login`);
            }
        });
        /* eslint-disable react-hooks/exhaustive-deps */
    }, []);

    return (<>
        <div className="app-page-title">
            <div className="page-title-wrapper">
                <div className="page-title-heading">
                    <div className="page-title-icon">
                        <i className="far fa-id-card icon-gradient bg-malibu-beach" style={{ fontSize: '25px' }}></i>
                    </div>
                    <div>
                        Detail Profile {dataUser.name} <Link to="/user/profile/form" className="btn btn-link btn-sm ml-2"><i className="far fa-edit"></i> Edit</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12">
                <div className="main-card mb-5 card">
                    <div className="card-body">
                        <h5 className="card-title">Detail Profile {dataUser.name}</h5><hr />
                        <div className="forms-wizard-vertical sw-main sw-theme-default">
                            <ul className="forms-wizard nav nav-tabs step-anchor">
                                <li className={`nav-item clickable ${aktif === 'Identitas Pribadi' ? 'active' : ''}`}>
                                    <Link to="#step-1" className="nav-link" onClick={setPosisi('one')}>
                                        <em>1</em>
                                        <span>Identitas Pribadi</span>
                                    </Link>
                                </li>
                                <li className={`nav-item clickable ${aktif === 'Status Keluarga' ? 'active' : ''}`}>
                                    <Link to="#step-2" className="nav-link" onClick={setPosisi('two')}>
                                        <em>2</em>
                                        <span>Status Keluarga</span>
                                    </Link>
                                </li>
                                <li className={`nav-item clickable ${aktif === 'Riwayat Pendidikan' ? 'active' : ''}`}>
                                    <Link to="#step-3" className="nav-link" onClick={setPosisi('three')}>
                                        <em>3</em>
                                        <span>Riwayat Pendidikan</span>
                                    </Link>
                                </li>
                                <li className={`nav-item clickable ${aktif === 'Riwayat Pekerjaan' ? 'active' : ''}`}>
                                    <Link to="#step-4" className="nav-link" onClick={setPosisi('four')}>
                                        <em>4</em>
                                        <span>Riwayat Pekerjaan</span>
                                    </Link>
                                </li>
                                <li className={`nav-item clickable ${aktif === 'Lain-lain' ? 'active' : ''}`}>
                                    <Link to="#step-5" className="nav-link" onClick={setPosisi('five')}>
                                        <em>5</em>
                                        <span>Lain-lain</span>
                                    </Link>
                                </li>
                            </ul>
                            <div className="form-wizard-content">
                                <div id="one" className="form-wizard-custom">
                                    <IdentitasPribadi next={() => setPosition('two')} data={data} />
                                </div>
                                <div id="two" className="form-wizard-custom">
                                    <StatusKeluarga next={() => setPosition('three')} prev={() => setPosition('one')} data={data} />
                                </div>
                                <div id="three" className="form-wizard-custom">
                                    <RiwayatPendidikan next={() => setPosition('four')} prev={() => setPosition('two')} data={data} />
                                </div>
                                <div id="four" className="form-wizard-custom">
                                    <RiwayatPekerjaan next={() => setPosition('five')} prev={() => setPosition('three')} data={data} />
                                </div>
                                <div id="five" className="form-wizard-custom">
                                    <LainLain prev={() => setPosition('four')} data={data} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ToastContainer />
    </>);
}
 
export default UserDetail;