import React, { useState, useEffect } from 'react';

const RiwayatPekerjaan = (props) => {
    const [pekerjaan, setPekerjaan] = useState([
        {
            no: '1.',
            tahun: '',
            nama_alamat: '',
            bidang_usaha: '',
            jabatan_terakhir: '',
            gaji_terakhir: '',
            alasan_berhenti: '',
        },
        {
            no: '2.',
            tahun: '',
            nama_alamat: '',
            bidang_usaha: '',
            jabatan_terakhir: '',
            gaji_terakhir: '',
            alasan_berhenti: '',
        },
        {
            no: '3.',
            tahun: '',
            nama_alamat: '',
            bidang_usaha: '',
            jabatan_terakhir: '',
            gaji_terakhir: '',
            alasan_berhenti: '',
        },
        {
            no: '4.',
            tahun: '',
            nama_alamat: '',
            bidang_usaha: '',
            jabatan_terakhir: '',
            gaji_terakhir: '',
            alasan_berhenti: '',
        },
        {
            no: '5.',
            tahun: '',
            nama_alamat: '',
            bidang_usaha: '',
            jabatan_terakhir: '',
            gaji_terakhir: '',
            alasan_berhenti: '',
        },
        {
            no: '6.',
            tahun: '',
            nama_alamat: '',
            bidang_usaha: '',
            jabatan_terakhir: '',
            gaji_terakhir: '',
            alasan_berhenti: '',
        },
    ]);

    useEffect(() => {
        if (props.data.uid && props.data.riwayat_pekerjaan) {
            let riwayatPekerjaan = props.data.riwayat_pekerjaan.split("|");
            const pekerjaanValues = [];
            let riwPekerjaan;
            for (let i = 0; i < riwayatPekerjaan.length; i++) {
                riwPekerjaan = riwayatPekerjaan[i].split("~");
                pekerjaanValues.push({
                    no: (i+1) + '.',
                    tahun: riwPekerjaan[0],
                    nama_alamat: riwPekerjaan[1],
                    bidang_usaha: riwPekerjaan[2],
                    jabatan_terakhir: riwPekerjaan[3],
                    gaji_terakhir: riwPekerjaan[4],
                    alasan_berhenti: riwPekerjaan[5],
                });
            }
            setPekerjaan(pekerjaanValues);
        }
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [props.data]);

    return (<>
        <p className="mb-2 font-weight-bold">Riwayat Pekerjaan dimulai dari pekerjaan yang terakhir :</p>
        <div className="table-responsive">
            <table className="table table-bordered table-hover">
                <thead>
                    <tr>
                        <th>No.</th>
                        <th>Tahun/Periode</th>
                        <th>Nama & Alamat Perusahaan</th>
                        <th>Bidang Usaha</th>
                        <th>Jabatan Terakhir</th>
                        <th>Gaji Terakhir</th>
                        <th>Alasan Berhenti</th>
                    </tr>
                </thead>
                <tbody>
                    {pekerjaan.map((d, i) => {
                        return <tr key={i}>
                            <td>{d.no}</td>
                            <td>{d.tahun}</td>
                            <td>{d.nama_alamat}</td>
                            <td>{d.bidang_usaha}</td>
                            <td>{d.jabatan_terakhir}</td>
                            <td>{d.gaji_terakhir}</td>
                            <td>{d.alasan_berhenti}</td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
        <div className="mt-4">
            <div className="divider"></div>
            <button className="btn-hover-shine btn btn-shadow btn-primary btn-wide btn-pill float-right" type="button" onClick={() => props.next()}>Next</button>
            <button type="button" className="btn-shadow float-right btn-wide btn-pill mr-3 btn btn-outline-secondary" onClick={() => props.prev()}>Previous</button>
        </div>
    </>);
}
 
export default RiwayatPekerjaan;