import React, { createContext } from 'react';

export const UserContext = createContext();

const UserContextProvider = (props) => {
    const userInfo = {
        user: JSON.parse(localStorage.getItem('user-token'))
    }

    return (
        <UserContext.Provider value={{ ...userInfo }}>
            {props.children}
        </UserContext.Provider>
    );
}
 
export default UserContextProvider;