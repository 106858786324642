import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import isEmail from 'validator/lib/isEmail';

const RecoverPassword = (props) => {
    const [form, setForm] = useState({
        email: '',
    });
    const [error, setError] = useState({
        email: '',
    });
    const [isSubmitting, setSubmitting] = useState(false);
    const handleChange = e => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
        setError({
            ...error,
            [e.target.name]: ''
        });
    }

    const validate = () => {
        const newError = { ...error };

        if (!form.email) {
            newError.email = "Email wajib diisi";
        } else if (form.email && !isEmail(form.email)) {
            newError.email = "Email tidak valid";
        } else {
            newError.email = "";
        }

        return newError;
    }

    const handleSubmit = e => {
        e.preventDefault();
        const findErrors = validate();

        if (Object.values(findErrors).some(err => err !== '')) {
            setError(findErrors);
        } else {
            setSubmitting(true);
            Swal.fire({
                text: "Sending password recovery mail...",
                imageUrl: "/assets/images/ajaxloader.gif",
                showConfirmButton: false,
                allowOutsideClick: false,
            });

            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_HOST}/users/send-reset-password-email`,
                responseType: 'json',
                headers: {'Accept': 'application/json'},
                data: {
                    email: form.email,
                }
            }).then(res => {
                Swal.close();
                Swal.fire("Sukses", res.data, "success");
                setSubmitting(false);
            }).catch(err => {
                setSubmitting(false);
                Swal.close();
                Swal.fire("Oops..", err?.response?.data, "error");
            });
        }
    }

    return (
    <>
        <div className="app-container app-theme-white body-tabs-shadow">
            <div className="app-container">
                <div className="h-100 bg-plum-plate bg-animation">
                    <div className="d-flex h-100 justify-content-center align-items-center">
                        <div className="mx-auto app-login-box col-md-8">
                            <center>
                                <img src="/assets/images/logo_yhc_lms.svg" className="img-responsive" alt="Logo-YHC" width="250" />
                            </center>
                            <div className="modal-dialog w-100 mx-auto">
                                <div className="modal-content">
                                    <form className="" onSubmit={handleSubmit} noValidate>
                                        <div className="modal-header">
                                            <div className="h5 modal-title">
                                                Forgot your Password?
                                                <h6 className="mt-1 mb-0 opacity-8">
                                                    <span>Use the form below to recover it.</span>
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="modal-body">
                                            <div className="form-row">
                                                <div className="col-md-12">
                                                    <div className="position-relative form-group">
                                                        <input name="email" id="exampleEmail"
                                                            placeholder="Email here..." type="email" className={error.email ? 'form-control is-invalid' : 'form-control'} required="" defaultValue={form.email} onChange={handleChange} disabled={isSubmitting} />
                                                        <em className={error.email ? `error invalid-feedback` : `d-none`}>{error.email}</em>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer clearfix">
                                            <div className="float-left">
                                                <Link to="/" className="btn-lg btn btn-link">Sign in existing account</Link>
                                            </div>
                                            <div className="float-right">
                                                <button className="btn btn-primary btn-lg" type="submit" disabled={isSubmitting}>
                                                {
                                                    !isSubmitting ?
                                                    <span>Recover Password</span>
                                                    :
                                                    <span><img src="/assets/images/ajaxloader.gif" className="img-fluid" style={{width: '18px'}} alt="" /> Recovering Password</span>
                                                }
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="text-center text-white opacity-8 mt-3">Copyright © YHC Users Management 2021</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
}

export default RecoverPassword;
