import React from 'react';

const UserNotFound = () => {
    return (<>
        <div className="row">
            <div className="col-md-12">
                <div className="main-card mb-3 card">
                    <div className="card-body text-center">
                        <img className="img-fluid" width="80%" src="/assets/images/undraw_page_not_found_su7k.svg" alt="page-not-found" />
                        <h1>Oops.. Page not found</h1>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default UserNotFound;