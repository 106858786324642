import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import $ from 'jquery';
import qs from 'qs';
import DataTable from 'react-data-table-component';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ToastContainer } from "react-toastify";
import { AdminContext } from '../../components/AdminContext';
import refreshToken from '../../configs/refreshToken';

const Users = (props) => {
    const { admin } = useContext(AdminContext);
    const unit_id = qs.parse(props.location.search, { ignoreQueryPrefix: true }).unit ?? '';

    const [cari, setCari] = useState('');
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [loadingUnit, setLoadingUnit] = useState(true);
    const [units, setUnits] = useState([]);

    let columns = [];
    if ($(window).width() > 768) {
        columns = [
            {
                name: 'No.',
                button: false,
                sortable: false,
                maxWidth: '30px',
                cell: (row, i) => (i+1) + '.',
            },
            {
                name: 'Unit',
                selector: 'unit',
                sortable: true,
                wrap: true,
            },
            {
                name: 'NIK',
                selector: 'nik',
                sortable: true,
                wrap: true,
            },
            {
                name: 'Nama',
                selector: 'name',
                sortable: true,
                wrap: true,
            },
            {
                name: 'Email',
                selector: 'email',
                sortable: true,
                wrap: true,
            },
        ];
    } else {
        columns = [
            {
                name: 'No.',
                button: false,
                sortable: false,
                maxWidth: '30px',
                cell: (row, i) => (i+1) + '.',
            },
            {
                name: 'Unit',
                selector: 'unit',
                sortable: true,
                wrap: true,
            },
            {
                name: 'Nama',
                selector: 'name',
                sortable: true,
                wrap: true,
            },
        ];
    }

    const customStyles = {
        headCells: {
          style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
          },
        },
        cells: {
          style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
          },
        },
    };

    const ActionExpandedComponent = ({data}) => {
        return <div style={{padding: '12px'}}>
            { ($(window).width() < 768) ?
            <>
                <div className="mb-2"><b>NIK: </b>{data.nik}</div>
                <div><b>Email: </b>{data.email}</div><hr />
            </>
            : ''}
            <Link to={`/admin/users/form/${data.id}`} className="mr-2 btn btn-hover-shine btn-sm btn-shadow btn-warning"><i className="fas fa-pencil-alt text-white"></i></Link>
            <button type="button" className="mr-2 btn btn-hover-shine btn-sm btn-shadow btn-danger" onClick={deleteData(data.id, data.name)}><i className="fas fa-trash"></i></button>
            <Link to={`/admin/users/detail/${data.id}`} className="mr-2 btn btn-hover-shine btn-sm btn-shadow btn-info"><i className="fas fa-list"></i></Link>
            <button type="button" className="btn btn-hover-shine btn-sm btn-shadow btn-secondary" onClick={showModalUserRole(data)}>Set Roles</button>
        </div>
    }

    const deleteData = (id, nama) => e => {
        Swal.fire({
            title: 'Yakin Ingin Hapus?',
            html: "Yakin Ingin Hapus Data Karyawan <b>'"+nama+"'</b>?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, hapus!'
        }).then((result) => {
            if (result.value) {
                setLoading(true);

                axios({
                    method: 'delete',
                    url: `${process.env.REACT_APP_API_HOST}/users/delete-data-users/${id}`,
                    responseType: 'json',
                    data: {},
                    headers: {
                        'Authorization': admin.token,
                        'Accept': 'application/json'
                    }
                }).then(res => {
                    if (res.data === "Sukses") {
                        Swal.fire({
                            title: "Sukses!",
                            html: "Hapus data <b>'"+nama+"'</b> berhasil!",
                            icon: "success",
                            showCancelButton: false,
                            confirmButtonText: "Ok",
                        }).then((result) => {
                          getData(unit_id);
                        });
                    } else {
                        Swal.fire("Oops..", "Hapus data '"+nama+"' gagal!", "error");
                        setLoading(false);
                    }
                }).catch(async err => {
                    if (err?.response?.status === 403) {
                        await refreshToken.refresh(process.env.REACT_APP_API_HOST, admin, 'admin');
                        props.history.push(`/login`);
                    } else {
                        Swal.fire("Oops..", "Terjadi kesalahan. Silakan coba lagi", "warning");
                        localStorage.clear();
                        props.history.push(`/login`);
                    }
                });
            }
        });
    }

    const getData = (unit_id) => {
        let mounted = true;
        setLoading(true);

        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_HOST}/users/get-data-users`,
            responseType: 'json',
            data: {
                'cari': cari,
                'unit': unit_id,
            },
            headers: {
                'Authorization': admin.token,
                'Accept': 'application/json'
            }
        }).then(res => {
            if (mounted) {
                setLoading(false);
                setData(res.data);
            }
        }).catch(async err => {
            if (err?.response?.status === 403) {
                await refreshToken.refresh(process.env.REACT_APP_API_HOST, admin, 'admin');
                props.history.push(`/login`);
            } else {
                localStorage.clear();
                props.history.push(`/login`);
            }
        });
    }

    const getUnits = () => {
        let mounted = true;
        setLoadingUnit(true);

        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_HOST}/users/get-data-unit`,
            responseType: 'json',
            headers: {
                'Authorization': admin.token,
                'Accept': 'application/json'
            }
        }).then(res => {
            if (mounted) {
                setLoadingUnit(false);

                let datas = [];
                for (let i = 0; i < res.data.length; i++) {
                    datas.push({
                        id: res.data[i].id,
                        unit: res.data[i].unit,
                        logo: res.data[i].logo,
                    });
                }

                let checkColum = res.data.length % 4;
                let additionalColum = 4 - checkColum;
                if (checkColum > 0) {
                    for (let i = 0; i < additionalColum; i++) {
                        // add empty column
                        datas.push({
                            id: '0',
                            unit: '',
                            logo: '',
                        });
                    }
                }
                setUnits(datas);
            }
        }).catch(async err => {
            if (err?.response?.status === 403) {
                await refreshToken.refresh(process.env.REACT_APP_API_HOST, admin, 'admin');
                props.history.push(`/login`);
            } else {
                localStorage.clear();
                props.history.push(`/login`);
            }
        });
    }

    const showModalUserRole = (data) => e => {
        $("#role-values").val(data.roles);
        $("#role-uid").val(data.id);
        $("#role-nama-user").text(data.name);
        $('#modalRoles').click();
    }

    const useStyles = makeStyles(theme => ({
        root: {
          width: '100%',
          '& > * + *': {
            marginTop: theme.spacing(2),
          },
        },
    }));

    const LinearIndeterminate = () => {
        const classes = useStyles();

        return (
            <div className={classes.root}>
                <LinearProgress />
            </div>
        );
    };

    useEffect(() => {
        // jika lebar window kurang dari 992px maka trigger click button close-sidebar
        if ($(window).width() < 992 && $('.mobile-toggle-nav.is-active').length > 0) {
            $('.app-header__mobile-menu .mobile-toggle-nav-left').click();
        }

        /* eslint-disable react-hooks/exhaustive-deps */
        getUnits();
        if (unit_id !== '') {
            getData(unit_id);
        }
    }, [cari, unit_id]);

    return (<>
        <div className="app-page-title">
            <div className="page-title-wrapper">
                <div className="page-title-heading">
                    <div className="page-title-icon">
                        <i className="fa fa-users icon-gradient bg-sunny-morning" style={{ fontSize: '25px' }}></i>
                    </div>
                    <div>
                        Data Karyawan
                        <div className="page-title-subheading">Data Karyawan di Yayasan Hasnur Centre</div>
                    </div>
                </div>
                <div className="page-title-actions">
                    {unit_id !== '' ?
                    <Link to="/admin/users" className="btn-shadow btn btn-hover-shine btn-outline-secondary mr-2">
                        <i className="fa fa-arrow-left"></i> Pilih Unit
                    </Link> : ''}
                    <Link to="/admin/users/form" className="btn-shadow btn btn-hover-shine btn-success">
                        <i className="fa fa-plus"></i> Input Data Karyawan
                    </Link>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12">
                {unit_id === '' ?
                <div className="main-card mb-3 card">
                    <div className="card-header">Pilih Unit</div>
                    <div className="card-body">
                        <div className="grid-menu grid-menu-4col">
                            <div className="no-gutters row">
                                {units.map((d, i) => {
                                    return <div className="col-sm-3" key={i}>
                                        {d.unit !== '' ?
                                        <Link to={`/admin/users?unit=${d.id}`} className="btn-icon-vertical btn-square btn-transition btn btn-outline-link">
                                            {d.logo !== '' && d.logo !== null ? <img src={`${process.env.REACT_APP_API_SERVICE}/units/${d.logo}`} className="img-fluid" alt="Logo Unit" /> : <i className="lnr-picture btn-icon-wrapper btn-icon-lg mb-3"></i>}
                                            {d.unit}
                                        </Link>
                                        : ''}
                                    </div>
                                })}
                            </div>
                        </div>
                        {loadingUnit ?
                        <div className="loader-wrapper d-flex justify-content-center align-items-center">
                            <div className="loader">
                                <div className="ball-grid-pulse">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                <div className="text-center" style={{ color: '#A2A6AA' }}>Loading</div>
                            </div>
                        </div> : <></>}
                    </div>
                </div> : ''}

                {unit_id !== '' ?
                <div className="main-card mb-3 card">
                    <div className="card-body" id="card-body-users">
                        <h5 className="card-title">Data Karyawan</h5><hr className="mb-0" />
                        <DataTable
                            noHeader={true}
                            columns={columns}
                            persistTableHead
                            data={data}
                            Clicked
                            striped
                            pagination={true}
                            highlightOnHover={true}
                            progressPending={loading}
                            progressComponent={<LinearIndeterminate />}
                            customStyles={customStyles}
                            expandableRows
                            expandableRowsComponent={<ActionExpandedComponent />}
                            subHeader={true}
                            subHeaderAlign={'right'}
                            subHeaderComponent={
                                (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <label>Cari: </label>
                                        <input className="form-control ml-2" name="cari" type="text" onKeyUp={(e) => {
                                            setCari(e.target.value)
                                        }} defaultValue={cari}  />
                                    </div>
                                )
                            }
                        />
                    </div>
                </div> : ''}
            </div>
        </div>

        <ToastContainer />
    </>);
}
 
export default Users;