import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import { AdminContext } from '../../../components/AdminContext';
import refreshToken from '../../../configs/refreshToken';

import IdentitasPribadi from './identitas-pribadi';
import StatusKeluarga from './status-keluarga';
import RiwayatPendidikan from './riwayat-pendidikan';
import RiwayatPekerjaan from './riwayat-pekerjaan';
import LainLain from './lain-lain';

const UsersForm = (props) => {
    const { admin } = useContext(AdminContext);
    const [position, setPosition] = useState('one');
    const [title, setTitle] = useState('');
    const [data, setData] = useState([]);

    useEffect(() => {
        $(".form-wizard-custom").hide();
        switch (position) {
            case 'one':
                $("#one").show();
                setTitle('Identitas Pribadi');
                break;
            case 'two':
                $("#two").show();
                setTitle('Status Keluarga');
                break;
            case 'three':
                $("#three").show();
                setTitle('Riwayat Pendidikan');
                break;
            case 'four':
                $("#four").show();
                setTitle('Riwayat Pekerjaan');
                break;
            case 'five':
                $("#five").show();
                setTitle('Lain-lain');
                break;
            default:
                $("#one").show();
                setTitle('Identitas Pribadi');
                break;
        }
    }, [position]);

    const editData = async (id) => {
        await axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_HOST}/users/edit-data-users`,
            responseType: 'json',
            data: {
                id: id
            },
            headers: {
                'Authorization': admin.token,
                'Accept': 'application/json'
            }
        }).then(res => {
            if (res.data.id === undefined) {
                props.history.push(`/admin/users`);
            }
            setData(res.data);
        }).catch(async err => {
            if (err?.response?.status === 403) {
                await refreshToken.refresh(process.env.REACT_APP_API_HOST, admin, 'admin');
                props.history.push(`/login`);
            } else {
                localStorage.clear();
                props.history.push(`/login`);
            }
        });
    }

    useEffect(() => {
        if (localStorage.getItem('uid')) {
            localStorage.removeItem('uid');
        }

        // jika form edit
        if (props.match.params.id !== undefined) {
            $("#btn-submit1").prop('disabled', true);
            editData(props.match.params.id);
        }
        /* eslint-disable react-hooks/exhaustive-deps */
    }, []);

    return (<>
        <div className="app-page-title">
            <div className="page-title-wrapper">
                <div className="page-title-heading">
                    <div className="page-title-icon">
                        <i className="fa fa-user-edit icon-gradient bg-malibu-beach" style={{ fontSize: '25px' }}></i>
                    </div>
                    <div>
                        Form Karyawan
                        <div className="page-title-subheading">Silakan isi form karyawan dibawah ini</div>
                    </div>
                </div>
                <div className="page-title-actions">
                    <Link to="/admin/users" className="btn-shadow btn btn-hover-shine btn-secondary">
                        <i className="fa fa-arrow-left"></i> Kembali
                    </Link>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12">
                <div className="main-card mb-5 card">
                    <div className="card-body">
                        <h5 className="card-title">Form Karyawan - {title}</h5><hr />
                        <div className="form-wizard-content">
                            <div id="one" className="form-wizard-custom">
                                <IdentitasPribadi next={() => setPosition('two')} data={data} />
                            </div>
                            <div id="two" className="form-wizard-custom">
                                <StatusKeluarga next={() => setPosition('three')} prev={() => setPosition('one')} data={data} />
                            </div>
                            <div id="three" className="form-wizard-custom">
                                <RiwayatPendidikan next={() => setPosition('four')} prev={() => setPosition('two')} data={data} />
                            </div>
                            <div id="four" className="form-wizard-custom">
                                <RiwayatPekerjaan next={() => setPosition('five')} prev={() => setPosition('three')} data={data} />
                            </div>
                            <div id="five" className="form-wizard-custom">
                                <LainLain prev={() => setPosition('four')} data={data} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default UsersForm;