import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// css assets
import './assets/vendors/@fortawesome/fontawesome-free/css/all.min.css';
import './assets/vendors/ionicons-npm/css/ionicons.css';
import './assets/vendors/linearicons-master/dist/web-font/style.css';
import './assets/vendors/pixeden-stroke-7-icon-master/pe-icon-7-stroke/dist/pe-icon-7-stroke.css';
import './assets/vendors/datatables/dataTables.bootstrap4.min.css';
import './assets/vendors/datatables/buttons.bootstrap4.min.css';
import './assets/vendors/datatables/responsive.bootstrap4.min.css';
import './assets/styles/css/base.css';
import './assets/styles/css/style.css';
import 'react-toastify/dist/ReactToastify.css';

import AdminRoute from './components/AdminRoute';
import UserRoute from './components/UserRoute';
import Login from './pages/auth/login';
import RecoverPassword from './pages/auth/recover-password';
import ResetPassword from './pages/auth/reset-password';
import Admin from './pages/admin';
import User from './pages/users';
import NotFoundPage from './404';

function App() {
  return (
    <Router>
      <Switch>
          <Route path="/" exact component={Login} />
          <Route path="/login" component={Login} />
          <Route path="/recover-password" component={RecoverPassword} />
          <Route path="/reset-password/:token" component={ResetPassword} />
          <AdminRoute path="/admin" component={Admin} />
          <UserRoute path="/user" component={User} />
          <Route component={NotFoundPage} />
      </Switch>
    </Router>
  );
}

export default App;
