import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

const ResetPassword = (props) => {
    const [form, setForm] = useState({
        new_password: '',
        new_password_confirmation: '',
    });
    const [error, setError] = useState({
        new_password: '',
        new_password_confirmation: '',
    });
    const [invalid, setInvalid] = useState(false);
    const [validationError, setValidationError] = useState({});
    const [isSubmitting, setSubmitting] = useState(false);
    const handleChange = e => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
        setError({
            ...error,
            [e.target.name]: ''
        });
    }

    const validate = () => {
        const newError = { ...error };

        if (!form.new_password) {
            newError.new_password = 'New Password wajib diisi';
        } else {
            newError.new_password = '';
        }

        if (!form.new_password_confirmation) {
            newError.new_password_confirmation = 'New Password Confirmation wajib diisi';
        } else {
            newError.new_password_confirmation = '';
        }

        return newError;
    }

    const handleSubmit = e => {
        e.preventDefault();
        const findErrors = validate();

        if (Object.values(findErrors).some(err => err !== '')) {
            setError(findErrors);
        } else {
            setSubmitting(true);
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_HOST}/users/reset-password`,
                responseType: 'json',
                headers: {'Accept': 'application/json'},
                data: {
                    token: props.match.params.token,
                    new_password: form.new_password,
                    new_password_confirmation: form.new_password_confirmation,
                }
            }).then(res => {
                Swal.fire("Sukses", res.data, "success");
                props.history.push('/login');
                setInvalid(false);
            }).catch(err => {
                if (err.response.status === 422) {
                    setInvalid(true);
                    let error = {};
                    for (let i = 0; i < Object.keys(err.response.data).length; i++) {
                        error[Object.keys(err.response.data)[i]] = Object.values(err.response.data)[i].join(", ");
                    }
                    setValidationError(error);
                } else {
                    Swal.fire("Oops..", err?.response?.data, "error");
                }
                setSubmitting(false);
            });
        }
    }

    return (
    <>
        <div className="app-container app-theme-white body-tabs-shadow">
            <div className="app-container">
                <div className="h-100 bg-plum-plate bg-animation">
                    <div className="d-flex h-100 justify-content-center align-items-center">
                        <div className="mx-auto app-login-box col-md-8">
                            <center>
                                <img src="/assets/images/logo_yhc_lms.svg" className="img-responsive" alt="Logo-YHC" width="250" />
                            </center>
                            <div className="modal-dialog w-100 mx-auto">
                                <div className="modal-content">
                                    <form className="" onSubmit={handleSubmit} noValidate>
                                        <div className="modal-header">
                                            <div className="h5 modal-title">
                                                Reset Password
                                                <h6 className="mt-1 mb-0 opacity-8">
                                                    <span>Use the form below to reset password.</span>
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="modal-body">
                                            {invalid ? <>
                                            <div className="alert alert-warning alert-dismissible fade show" role="alert">
                                                <ul className="pl-3 m-0">
                                                    {Object.keys(validationError).map((d, i) => {
                                                        return <li key={i}>{validationError[d]}</li>
                                                    })}
                                                </ul>
                                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            </> : <></>}
                                            <div className="form-row">
                                                <div className="col-md-12">
                                                    <div className="position-relative form-group">
                                                        <input name="new_password" placeholder="New Password" type="password" className={error.new_password ? 'form-control is-invalid' : 'form-control'} required="" defaultValue={form.new_password} onChange={handleChange} disabled={isSubmitting} />
                                                        <em className={error.new_password ? `error invalid-feedback` : `d-none`}>{error.new_password}</em>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="position-relative form-group">
                                                        <input name="new_password_confirmation" placeholder="New Password Confirmation" type="password" className={error.new_password_confirmation ? 'form-control is-invalid' : 'form-control'} required="" defaultValue={form.new_password_confirmation} onChange={handleChange} disabled={isSubmitting} />
                                                        <em className={error.new_password_confirmation ? `error invalid-feedback` : `d-none`}>{error.new_password_confirmation}</em>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer clearfix">
                                            <div className="float-right">
                                                <button className="btn btn-primary btn-lg" type="submit" disabled={isSubmitting}>
                                                {
                                                    !isSubmitting ?
                                                    <span>Reset Password</span>
                                                    :
                                                    <span><img src="/assets/images/ajaxloader.gif" className="img-fluid" style={{width: '18px'}} alt="" /> Resetting Password</span>
                                                }
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="text-center text-white opacity-8 mt-3">Copyright © YHC Users Management 2021</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
}

export default ResetPassword;
