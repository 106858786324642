import React, { useState, useEffect } from 'react';

const LainLain = (props) => {
    const [kegiatanOrganisasi, setKegiatanOrganisasi] = useState([
        {
            no: '1.',
            nama: '',
            jenis: '',
            jabatan: '',
            tahun: '',
        },
        {
            no: '2.',
            nama: '',
            jenis: '',
            jabatan: '',
            tahun: '',
        },
        {
            no: '3.',
            nama: '',
            jenis: '',
            jabatan: '',
            tahun: '',
        },
        {
            no: '4.',
            nama: '',
            jenis: '',
            jabatan: '',
            tahun: '',
        },
        {
            no: '5.',
            nama: '',
            jenis: '',
            jabatan: '',
            tahun: '',
        },
    ]);
    const [infoFisik, setInfoFisik] = useState({
        tinggi_badan: '',
        berat_badan: '',
        gol_darah: '',
        ukuran_pakaian: '',
        ukuran_sepatu: '',
    });
    const [pihakDarurat, setPihakDarurat] = useState({
        nama: '',
        hubungan: '',
        alamat: '',
        no_telp_rumah: '',
        no_telp_kantor: '',
    });

    useEffect(() => {
        // jika form edit
        if (props.data.uid && props.data.kegiatan_organisasi && props.data.info_fisik && props.data.pihak_darurat) {
            let kegiatan_organisasi = props.data.kegiatan_organisasi.split("|");
            let info_fisik = props.data.info_fisik.split("|");
            let pihak_darurat = props.data.pihak_darurat.split("|");

            const kegiatanOrganisasiValues = [];
            let kegOrg;
            for (let i = 0; i < kegiatan_organisasi.length; i++) {
                kegOrg = kegiatan_organisasi[i].split("~");
                kegiatanOrganisasiValues.push({
                    no: (i+1) + '.',
                    nama: kegOrg[0],
                    jenis: kegOrg[1],
                    jabatan: kegOrg[2],
                    tahun: kegOrg[3],
                });
            }
            setKegiatanOrganisasi(kegiatanOrganisasiValues);

            const newInfoFisik = {...infoFisik};
            newInfoFisik.tinggi_badan = info_fisik[0];
            newInfoFisik.berat_badan = info_fisik[1];
            newInfoFisik.gol_darah = info_fisik[2];
            newInfoFisik.ukuran_pakaian = info_fisik[3];
            newInfoFisik.ukuran_sepatu = info_fisik[4];
            setInfoFisik(newInfoFisik);

            const newPihakDarurat = {...pihakDarurat};
            newPihakDarurat.nama = pihak_darurat[0];
            newPihakDarurat.hubungan = pihak_darurat[1];
            newPihakDarurat.alamat = pihak_darurat[2];
            newPihakDarurat.no_telp_rumah = pihak_darurat[3];
            newPihakDarurat.no_telp_kantor = pihak_darurat[4];
            setPihakDarurat(newPihakDarurat);
        }
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [props.data]);

    return (<>
        <p className="mb-2 font-weight-bold">1. Kegiatan Organisasi</p>
        <div className="table-responsive">
            <table className="table table-bordered table-hover">
                <thead>
                    <tr>
                        <th>No.</th>
                        <th>Nama Organisasi</th>
                        <th>Jenis Organisasi</th>
                        <th>Jabatan</th>
                        <th>Tahun/Periode</th>
                    </tr>
                </thead>
                <tbody>
                    {kegiatanOrganisasi.map((d, i) => {
                        return <tr key={i}>
                            <td>{d.no}</td>
                            <td>{d.nama}</td>
                            <td>{d.jenis}</td>
                            <td>{d.jabatan}</td>
                            <td>{d.tahun}</td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
        <br />
        <p className="mb-2 font-weight-bold">2. Informasi Fisik</p>
        <div className="table-responsive">
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>Tinggi Badan</th>
                        <th>Berat Badan</th>
                        <th>Gol. Darah</th>
                        <th>Ukuran Pakaian</th>
                        <th>Ukuran Sepatu</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{infoFisik.tinggi_badan}</td>
                        <td>{infoFisik.berat_badan}</td>
                        <td>{infoFisik.gol_darah}</td>
                        <td>{infoFisik.ukuran_pakaian}</td>
                        <td>{infoFisik.ukuran_sepatu}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <br />
        <p className="mb-2 font-weight-bold">3. Pihak yang dalam keadaan darurat yang bisa dihubungi:</p>
        <div className="table-responsive">
            <table className="table">
                <tbody>
                    <tr>
                        <td width="200"><b>a. Nama</b></td>
                        <td width="50">:</td>
                        <td>{pihakDarurat.nama}</td>
                    </tr>
                    <tr>
                        <td><b>b. Hubungan</b></td>
                        <td>:</td>
                        <td>{pihakDarurat.hubungan}</td>
                    </tr>
                    <tr>
                        <td><b>c. Alamat</b></td>
                        <td>:</td>
                        <td>{pihakDarurat.alamat}</td>
                    </tr>
                    <tr>
                        <td><b>d. No Telp Rumah/HP</b></td>
                        <td>:</td>
                        <td>{pihakDarurat.no_telp_rumah}</td>
                    </tr>
                    <tr>
                        <td><b>e. No Telp Kantor</b></td>
                        <td>:</td>
                        <td>{pihakDarurat.no_telp_kantor}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className="mt-4">
            <div className="divider"></div>
            <button type="button" className="btn-shadow float-right btn-wide btn-pill mr-3 btn btn-outline-secondary" onClick={() => props.prev()}>Previous</button>
        </div>
    </>);
}
 
export default LainLain;